// tslint:disable
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url'
import { Configuration } from './configuration'
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base'

/**
 *
 * @export
 * @interface AmountRecommendationResponse
 */
export interface AmountRecommendationResponse {
  /**
   *
   * @type {number}
   * @memberof AmountRecommendationResponse
   */
  amount?: number
  /**
   *
   * @type {string}
   * @memberof AmountRecommendationResponse
   */
  hint?: string
  /**
   *
   * @type {number}
   * @memberof AmountRecommendationResponse
   */
  calculationOption?: number
}
/**
 *
 * @export
 * @interface BUprsRecommendationsGetRecommendedAutopaymentDateRules
 */
export interface BUprsRecommendationsGetRecommendedAutopaymentDateRules {
  /**
   *
   * @type {boolean}
   * @memberof BUprsRecommendationsGetRecommendedAutopaymentDateRules
   */
  fixedOffset?: boolean
  /**
   *
   * @type {Array<BUprsRecommendationsGetRecommendedAutopaymentDateRulesTransitions>}
   * @memberof BUprsRecommendationsGetRecommendedAutopaymentDateRules
   */
  transitions?: Array<BUprsRecommendationsGetRecommendedAutopaymentDateRulesTransitions>
  /**
   *
   * @type {Array<BUprsRecommendationsGetRecommendedAutopaymentDateRulesTransitionRules>}
   * @memberof BUprsRecommendationsGetRecommendedAutopaymentDateRules
   */
  transitionRules?: Array<BUprsRecommendationsGetRecommendedAutopaymentDateRulesTransitionRules>
}
/**
 *
 * @export
 * @interface BUprsRecommendationsGetRecommendedAutopaymentDateRulesDuration
 */
export interface BUprsRecommendationsGetRecommendedAutopaymentDateRulesDuration {
  /**
   *
   * @type {number}
   * @memberof BUprsRecommendationsGetRecommendedAutopaymentDateRulesDuration
   */
  seconds?: number
  /**
   *
   * @type {number}
   * @memberof BUprsRecommendationsGetRecommendedAutopaymentDateRulesDuration
   */
  nano?: number
  /**
   *
   * @type {Array<BUprsRecommendationsGetRecommendedAutopaymentDateRulesDurationUnits>}
   * @memberof BUprsRecommendationsGetRecommendedAutopaymentDateRulesDuration
   */
  units?: Array<BUprsRecommendationsGetRecommendedAutopaymentDateRulesDurationUnits>
  /**
   *
   * @type {boolean}
   * @memberof BUprsRecommendationsGetRecommendedAutopaymentDateRulesDuration
   */
  zero?: boolean
  /**
   *
   * @type {boolean}
   * @memberof BUprsRecommendationsGetRecommendedAutopaymentDateRulesDuration
   */
  negative?: boolean
}
/**
 *
 * @export
 * @interface BUprsRecommendationsGetRecommendedAutopaymentDateRulesDurationUnits
 */
export interface BUprsRecommendationsGetRecommendedAutopaymentDateRulesDurationUnits {
  /**
   *
   * @type {boolean}
   * @memberof BUprsRecommendationsGetRecommendedAutopaymentDateRulesDurationUnits
   */
  durationEstimated?: boolean
  /**
   *
   * @type {boolean}
   * @memberof BUprsRecommendationsGetRecommendedAutopaymentDateRulesDurationUnits
   */
  dateBased?: boolean
  /**
   *
   * @type {boolean}
   * @memberof BUprsRecommendationsGetRecommendedAutopaymentDateRulesDurationUnits
   */
  timeBased?: boolean
}
/**
 *
 * @export
 * @interface BUprsRecommendationsGetRecommendedAutopaymentDateRulesOffsetBefore
 */
export interface BUprsRecommendationsGetRecommendedAutopaymentDateRulesOffsetBefore {
  /**
   *
   * @type {number}
   * @memberof BUprsRecommendationsGetRecommendedAutopaymentDateRulesOffsetBefore
   */
  totalSeconds?: number
  /**
   *
   * @type {string}
   * @memberof BUprsRecommendationsGetRecommendedAutopaymentDateRulesOffsetBefore
   */
  id?: string
}
/**
 *
 * @export
 * @interface BUprsRecommendationsGetRecommendedAutopaymentDateRulesTransitionRules
 */
export interface BUprsRecommendationsGetRecommendedAutopaymentDateRulesTransitionRules {
  /**
   *
   * @type {string}
   * @memberof BUprsRecommendationsGetRecommendedAutopaymentDateRulesTransitionRules
   */
  month?: BUprsRecommendationsGetRecommendedAutopaymentDateRulesTransitionRulesMonthEnum
  /**
   *
   * @type {string}
   * @memberof BUprsRecommendationsGetRecommendedAutopaymentDateRulesTransitionRules
   */
  timeDefinition?: BUprsRecommendationsGetRecommendedAutopaymentDateRulesTransitionRulesTimeDefinitionEnum
  /**
   *
   * @type {BUprsRecommendationsGetRecommendedAutopaymentDateRulesOffsetBefore}
   * @memberof BUprsRecommendationsGetRecommendedAutopaymentDateRulesTransitionRules
   */
  standardOffset?: BUprsRecommendationsGetRecommendedAutopaymentDateRulesOffsetBefore
  /**
   *
   * @type {BUprsRecommendationsGetRecommendedAutopaymentDateRulesOffsetBefore}
   * @memberof BUprsRecommendationsGetRecommendedAutopaymentDateRulesTransitionRules
   */
  offsetBefore?: BUprsRecommendationsGetRecommendedAutopaymentDateRulesOffsetBefore
  /**
   *
   * @type {BUprsRecommendationsGetRecommendedAutopaymentDateRulesOffsetBefore}
   * @memberof BUprsRecommendationsGetRecommendedAutopaymentDateRulesTransitionRules
   */
  offsetAfter?: BUprsRecommendationsGetRecommendedAutopaymentDateRulesOffsetBefore
  /**
   *
   * @type {number}
   * @memberof BUprsRecommendationsGetRecommendedAutopaymentDateRulesTransitionRules
   */
  dayOfMonthIndicator?: number
  /**
   *
   * @type {string}
   * @memberof BUprsRecommendationsGetRecommendedAutopaymentDateRulesTransitionRules
   */
  dayOfWeek?: BUprsRecommendationsGetRecommendedAutopaymentDateRulesTransitionRulesDayOfWeekEnum
  /**
   *
   * @type {LocalTime}
   * @memberof BUprsRecommendationsGetRecommendedAutopaymentDateRulesTransitionRules
   */
  localTime?: LocalTime
  /**
   *
   * @type {boolean}
   * @memberof BUprsRecommendationsGetRecommendedAutopaymentDateRulesTransitionRules
   */
  midnightEndOfDay?: boolean
}

/**
 * @export
 * @enum {string}
 */
export enum BUprsRecommendationsGetRecommendedAutopaymentDateRulesTransitionRulesMonthEnum {
  JANUARY = 'JANUARY',
  FEBRUARY = 'FEBRUARY',
  MARCH = 'MARCH',
  APRIL = 'APRIL',
  MAY = 'MAY',
  JUNE = 'JUNE',
  JULY = 'JULY',
  AUGUST = 'AUGUST',
  SEPTEMBER = 'SEPTEMBER',
  OCTOBER = 'OCTOBER',
  NOVEMBER = 'NOVEMBER',
  DECEMBER = 'DECEMBER',
}
/**
 * @export
 * @enum {string}
 */
export enum BUprsRecommendationsGetRecommendedAutopaymentDateRulesTransitionRulesTimeDefinitionEnum {
  UTC = 'UTC',
  WALL = 'WALL',
  STANDARD = 'STANDARD',
}
/**
 * @export
 * @enum {string}
 */
export enum BUprsRecommendationsGetRecommendedAutopaymentDateRulesTransitionRulesDayOfWeekEnum {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

/**
 *
 * @export
 * @interface BUprsRecommendationsGetRecommendedAutopaymentDateRulesTransitions
 */
export interface BUprsRecommendationsGetRecommendedAutopaymentDateRulesTransitions {
  /**
   *
   * @type {BUprsRecommendationsGetRecommendedAutopaymentDateRulesOffsetBefore}
   * @memberof BUprsRecommendationsGetRecommendedAutopaymentDateRulesTransitions
   */
  offsetBefore?: BUprsRecommendationsGetRecommendedAutopaymentDateRulesOffsetBefore
  /**
   *
   * @type {BUprsRecommendationsGetRecommendedAutopaymentDateRulesOffsetBefore}
   * @memberof BUprsRecommendationsGetRecommendedAutopaymentDateRulesTransitions
   */
  offsetAfter?: BUprsRecommendationsGetRecommendedAutopaymentDateRulesOffsetBefore
  /**
   *
   * @type {boolean}
   * @memberof BUprsRecommendationsGetRecommendedAutopaymentDateRulesTransitions
   */
  gap?: boolean
  /**
   *
   * @type {string}
   * @memberof BUprsRecommendationsGetRecommendedAutopaymentDateRulesTransitions
   */
  dateTimeBefore?: string
  /**
   *
   * @type {string}
   * @memberof BUprsRecommendationsGetRecommendedAutopaymentDateRulesTransitions
   */
  dateTimeAfter?: string
  /**
   *
   * @type {string}
   * @memberof BUprsRecommendationsGetRecommendedAutopaymentDateRulesTransitions
   */
  instant?: string
  /**
   *
   * @type {boolean}
   * @memberof BUprsRecommendationsGetRecommendedAutopaymentDateRulesTransitions
   */
  overlap?: boolean
  /**
   *
   * @type {BUprsRecommendationsGetRecommendedAutopaymentDateRulesDuration}
   * @memberof BUprsRecommendationsGetRecommendedAutopaymentDateRulesTransitions
   */
  duration?: BUprsRecommendationsGetRecommendedAutopaymentDateRulesDuration
}
/**
 *
 * @export
 * @interface CustomerTimeZone
 */
export interface CustomerTimeZone {
  /**
   *
   * @type {number}
   * @memberof CustomerTimeZone
   */
  totalSeconds?: number
  /**
   *
   * @type {string}
   * @memberof CustomerTimeZone
   */
  id?: string
  /**
   *
   * @type {BUprsRecommendationsGetRecommendedAutopaymentDateRules}
   * @memberof CustomerTimeZone
   */
  rules?: BUprsRecommendationsGetRecommendedAutopaymentDateRules
}
/**
 *
 * @export
 * @interface DateRecommendationResponse
 */
export interface DateRecommendationResponse {
  /**
   *
   * @type {string}
   * @memberof DateRecommendationResponse
   */
  date?: string
  /**
   *
   * @type {string}
   * @memberof DateRecommendationResponse
   */
  hint?: string
}
/**
 *
 * @export
 * @interface LocalTime
 */
export interface LocalTime {
  /**
   *
   * @type {number}
   * @memberof LocalTime
   */
  hour?: number
  /**
   *
   * @type {number}
   * @memberof LocalTime
   */
  minute?: number
  /**
   *
   * @type {number}
   * @memberof LocalTime
   */
  second?: number
  /**
   *
   * @type {number}
   * @memberof LocalTime
   */
  nano?: number
}

/**
 * UprsRecommendationControllerApi - axios parameter creator
 * @export
 */
export const UprsRecommendationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Возвращает сумму рекомендации для автоплатежа и подсказку для этой суммы
     * @summary Получение рекомендации по сумме автоплатежа
     * @param {string} customerPhone Номер телефона в 11-значном формате
     * @param {string} [serviceId] Идентификатор ТСП, в пользу которого совершается платёж
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecommendedAutopaymentAmount(customerPhone: string, serviceId?: string, options: any = {}): RequestArgs {
      // verify required parameter 'customerPhone' is not null or undefined
      if (customerPhone === null || customerPhone === undefined) {
        throw new RequiredError(
          'customerPhone',
          'Required parameter customerPhone was null or undefined when calling getRecommendedAutopaymentAmount.'
        )
      }
      const localVarPath = `/b/uprs-recommendations/getRecommendedAutopaymentAmount`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (customerPhone !== undefined) {
        localVarQueryParameter['customerPhone'] = customerPhone
      }

      if (serviceId !== undefined) {
        localVarQueryParameter['serviceId'] = serviceId
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Возвращает рекомендованную начальную дату для автоплатежа и подсказку для этой даты
     * @summary Получение рекомендации по дате автоплатежа
     * @param {string} customerPhone Номер телефона в 11-значном формате
     * @param {object} customerTimeZone Часовой пояс в формате +03:00
     * @param {string} [serviceId] Идентификатор ТСП, в пользу которого совершается платёж
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecommendedAutopaymentDate(
      customerPhone: string,
      customerTimeZone: object,
      serviceId?: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'customerPhone' is not null or undefined
      if (customerPhone === null || customerPhone === undefined) {
        throw new RequiredError(
          'customerPhone',
          'Required parameter customerPhone was null or undefined when calling getRecommendedAutopaymentDate.'
        )
      }
      // verify required parameter 'customerTimeZone' is not null or undefined
      if (customerTimeZone === null || customerTimeZone === undefined) {
        throw new RequiredError(
          'customerTimeZone',
          'Required parameter customerTimeZone was null or undefined when calling getRecommendedAutopaymentDate.'
        )
      }
      const localVarPath = `/b/uprs-recommendations/getRecommendedAutopaymentDate`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (customerPhone !== undefined) {
        localVarQueryParameter['customerPhone'] = customerPhone
      }

      if (customerTimeZone !== undefined) {
        localVarQueryParameter['customerTimeZone'] = customerTimeZone
      }

      if (serviceId !== undefined) {
        localVarQueryParameter['serviceId'] = serviceId
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Возвращает рекомендованный лимит для автоплатежа и подсказку для этого лимита
     * @summary Получение рекомендации по лимиту автоплатежа
     * @param {string} customerPhone Номер телефона в 11-значном формате
     * @param {string} [serviceId] Идентификатор ТСП, в пользу которого совершается платёж
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecommendedAutopaymentLimit(customerPhone: string, serviceId?: string, options: any = {}): RequestArgs {
      // verify required parameter 'customerPhone' is not null or undefined
      if (customerPhone === null || customerPhone === undefined) {
        throw new RequiredError(
          'customerPhone',
          'Required parameter customerPhone was null or undefined when calling getRecommendedAutopaymentLimit.'
        )
      }
      const localVarPath = `/b/uprs-recommendations/getRecommendedAutopaymentLimit`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (customerPhone !== undefined) {
        localVarQueryParameter['customerPhone'] = customerPhone
      }

      if (serviceId !== undefined) {
        localVarQueryParameter['serviceId'] = serviceId
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Возвращает сумму рекомендации для разового платежа и подсказку для этой суммы
     * @summary Получение рекомендации по сумме платежа
     * @param {string} customerPhone Номер телефона в 11-значном формате
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecommendedPaymentAmount(customerPhone: string, options: any = {}): RequestArgs {
      // verify required parameter 'customerPhone' is not null or undefined
      if (customerPhone === null || customerPhone === undefined) {
        throw new RequiredError(
          'customerPhone',
          'Required parameter customerPhone was null or undefined when calling getRecommendedPaymentAmount.'
        )
      }
      const localVarPath = `/b/uprs-recommendations/getRecommendedPaymentAmount`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (customerPhone !== undefined) {
        localVarQueryParameter['customerPhone'] = customerPhone
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Возвращает сумму рекомендации для разового платежа и подсказку для этой суммы
     * @summary Получение рекомендации по сумме платежа для номера лицевого счёта
     * @param {string} customerAccount Номер лицевого счёта
     * @param {string} serviceId Идентификатор ТСП, в пользу которого совершается платёж
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecommendedPaymentAmountByAccountNumber(
      customerAccount: string,
      serviceId: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'customerAccount' is not null or undefined
      if (customerAccount === null || customerAccount === undefined) {
        throw new RequiredError(
          'customerAccount',
          'Required parameter customerAccount was null or undefined when calling getRecommendedPaymentAmountByAccountNumber.'
        )
      }
      // verify required parameter 'serviceId' is not null or undefined
      if (serviceId === null || serviceId === undefined) {
        throw new RequiredError(
          'serviceId',
          'Required parameter serviceId was null or undefined when calling getRecommendedPaymentAmountByAccountNumber.'
        )
      }
      const localVarPath = `/b/uprs-recommendations/getRecommendedPaymentAmountByAccountNumber`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (customerAccount !== undefined) {
        localVarQueryParameter['customerAccount'] = customerAccount
      }

      if (serviceId !== undefined) {
        localVarQueryParameter['serviceId'] = serviceId
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Возвращает сумму рекомендации для разового платежа и подсказку для этой суммы
     * @summary Получение рекомендации по сумме платежа для номера телефона
     * @param {string} customerPhone Номер телефона в 11-значном формате
     * @param {string} serviceId Идентификатор ТСП, в пользу которого совершается платёж
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecommendedPaymentAmountByPhoneNumber(customerPhone: string, serviceId: string, options: any = {}): RequestArgs {
      // verify required parameter 'customerPhone' is not null or undefined
      if (customerPhone === null || customerPhone === undefined) {
        throw new RequiredError(
          'customerPhone',
          'Required parameter customerPhone was null or undefined when calling getRecommendedPaymentAmountByPhoneNumber.'
        )
      }
      // verify required parameter 'serviceId' is not null or undefined
      if (serviceId === null || serviceId === undefined) {
        throw new RequiredError(
          'serviceId',
          'Required parameter serviceId was null or undefined when calling getRecommendedPaymentAmountByPhoneNumber.'
        )
      }
      const localVarPath = `/b/uprs-recommendations/getRecommendedPaymentAmountByPhoneNumber`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (customerPhone !== undefined) {
        localVarQueryParameter['customerPhone'] = customerPhone
      }

      if (serviceId !== undefined) {
        localVarQueryParameter['serviceId'] = serviceId
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * UprsRecommendationControllerApi - functional programming interface
 * @export
 */
export const UprsRecommendationControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Возвращает сумму рекомендации для автоплатежа и подсказку для этой суммы
     * @summary Получение рекомендации по сумме автоплатежа
     * @param {string} customerPhone Номер телефона в 11-значном формате
     * @param {string} [serviceId] Идентификатор ТСП, в пользу которого совершается платёж
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecommendedAutopaymentAmount(
      customerPhone: string,
      serviceId?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AmountRecommendationResponse> {
      const localVarAxiosArgs = UprsRecommendationControllerApiAxiosParamCreator(
        configuration
      ).getRecommendedAutopaymentAmount(customerPhone, serviceId, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Возвращает рекомендованную начальную дату для автоплатежа и подсказку для этой даты
     * @summary Получение рекомендации по дате автоплатежа
     * @param {string} customerPhone Номер телефона в 11-значном формате
     * @param {object} customerTimeZone Часовой пояс в формате +03:00
     * @param {string} [serviceId] Идентификатор ТСП, в пользу которого совершается платёж
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecommendedAutopaymentDate(
      customerPhone: string,
      customerTimeZone: object,
      serviceId?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DateRecommendationResponse> {
      const localVarAxiosArgs = UprsRecommendationControllerApiAxiosParamCreator(
        configuration
      ).getRecommendedAutopaymentDate(customerPhone, customerTimeZone, serviceId, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Возвращает рекомендованный лимит для автоплатежа и подсказку для этого лимита
     * @summary Получение рекомендации по лимиту автоплатежа
     * @param {string} customerPhone Номер телефона в 11-значном формате
     * @param {string} [serviceId] Идентификатор ТСП, в пользу которого совершается платёж
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecommendedAutopaymentLimit(
      customerPhone: string,
      serviceId?: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AmountRecommendationResponse> {
      const localVarAxiosArgs = UprsRecommendationControllerApiAxiosParamCreator(
        configuration
      ).getRecommendedAutopaymentLimit(customerPhone, serviceId, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Возвращает сумму рекомендации для разового платежа и подсказку для этой суммы
     * @summary Получение рекомендации по сумме платежа
     * @param {string} customerPhone Номер телефона в 11-значном формате
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecommendedPaymentAmount(
      customerPhone: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AmountRecommendationResponse> {
      const localVarAxiosArgs = UprsRecommendationControllerApiAxiosParamCreator(
        configuration
      ).getRecommendedPaymentAmount(customerPhone, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Возвращает сумму рекомендации для разового платежа и подсказку для этой суммы
     * @summary Получение рекомендации по сумме платежа для номера лицевого счёта
     * @param {string} customerAccount Номер лицевого счёта
     * @param {string} serviceId Идентификатор ТСП, в пользу которого совершается платёж
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecommendedPaymentAmountByAccountNumber(
      customerAccount: string,
      serviceId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AmountRecommendationResponse> {
      const localVarAxiosArgs = UprsRecommendationControllerApiAxiosParamCreator(
        configuration
      ).getRecommendedPaymentAmountByAccountNumber(customerAccount, serviceId, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     * Возвращает сумму рекомендации для разового платежа и подсказку для этой суммы
     * @summary Получение рекомендации по сумме платежа для номера телефона
     * @param {string} customerPhone Номер телефона в 11-значном формате
     * @param {string} serviceId Идентификатор ТСП, в пользу которого совершается платёж
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecommendedPaymentAmountByPhoneNumber(
      customerPhone: string,
      serviceId: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AmountRecommendationResponse> {
      const localVarAxiosArgs = UprsRecommendationControllerApiAxiosParamCreator(
        configuration
      ).getRecommendedPaymentAmountByPhoneNumber(customerPhone, serviceId, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * UprsRecommendationControllerApi - factory interface
 * @export
 */
export const UprsRecommendationControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     * Возвращает сумму рекомендации для автоплатежа и подсказку для этой суммы
     * @summary Получение рекомендации по сумме автоплатежа
     * @param {string} customerPhone Номер телефона в 11-значном формате
     * @param {string} [serviceId] Идентификатор ТСП, в пользу которого совершается платёж
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecommendedAutopaymentAmount(
      customerPhone: string,
      serviceId?: string,
      options?: any
    ): AxiosPromise<AmountRecommendationResponse> {
      return UprsRecommendationControllerApiFp(configuration).getRecommendedAutopaymentAmount(
        customerPhone,
        serviceId,
        options
      )(axios, basePath)
    },
    /**
     * Возвращает рекомендованную начальную дату для автоплатежа и подсказку для этой даты
     * @summary Получение рекомендации по дате автоплатежа
     * @param {string} customerPhone Номер телефона в 11-значном формате
     * @param {object} customerTimeZone Часовой пояс в формате +03:00
     * @param {string} [serviceId] Идентификатор ТСП, в пользу которого совершается платёж
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecommendedAutopaymentDate(
      customerPhone: string,
      customerTimeZone: object,
      serviceId?: string,
      options?: any
    ): AxiosPromise<DateRecommendationResponse> {
      return UprsRecommendationControllerApiFp(configuration).getRecommendedAutopaymentDate(
        customerPhone,
        customerTimeZone,
        serviceId,
        options
      )(axios, basePath)
    },
    /**
     * Возвращает рекомендованный лимит для автоплатежа и подсказку для этого лимита
     * @summary Получение рекомендации по лимиту автоплатежа
     * @param {string} customerPhone Номер телефона в 11-значном формате
     * @param {string} [serviceId] Идентификатор ТСП, в пользу которого совершается платёж
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecommendedAutopaymentLimit(
      customerPhone: string,
      serviceId?: string,
      options?: any
    ): AxiosPromise<AmountRecommendationResponse> {
      return UprsRecommendationControllerApiFp(configuration).getRecommendedAutopaymentLimit(
        customerPhone,
        serviceId,
        options
      )(axios, basePath)
    },
    /**
     * Возвращает сумму рекомендации для разового платежа и подсказку для этой суммы
     * @summary Получение рекомендации по сумме платежа
     * @param {string} customerPhone Номер телефона в 11-значном формате
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecommendedPaymentAmount(customerPhone: string, options?: any): AxiosPromise<AmountRecommendationResponse> {
      return UprsRecommendationControllerApiFp(configuration).getRecommendedPaymentAmount(customerPhone, options)(
        axios,
        basePath
      )
    },
    /**
     * Возвращает сумму рекомендации для разового платежа и подсказку для этой суммы
     * @summary Получение рекомендации по сумме платежа для номера лицевого счёта
     * @param {string} customerAccount Номер лицевого счёта
     * @param {string} serviceId Идентификатор ТСП, в пользу которого совершается платёж
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecommendedPaymentAmountByAccountNumber(
      customerAccount: string,
      serviceId: string,
      options?: any
    ): AxiosPromise<AmountRecommendationResponse> {
      return UprsRecommendationControllerApiFp(configuration).getRecommendedPaymentAmountByAccountNumber(
        customerAccount,
        serviceId,
        options
      )(axios, basePath)
    },
    /**
     * Возвращает сумму рекомендации для разового платежа и подсказку для этой суммы
     * @summary Получение рекомендации по сумме платежа для номера телефона
     * @param {string} customerPhone Номер телефона в 11-значном формате
     * @param {string} serviceId Идентификатор ТСП, в пользу которого совершается платёж
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRecommendedPaymentAmountByPhoneNumber(
      customerPhone: string,
      serviceId: string,
      options?: any
    ): AxiosPromise<AmountRecommendationResponse> {
      return UprsRecommendationControllerApiFp(configuration).getRecommendedPaymentAmountByPhoneNumber(
        customerPhone,
        serviceId,
        options
      )(axios, basePath)
    },
  }
}

/**
 * UprsRecommendationControllerApi - object-oriented interface
 * @export
 * @class UprsRecommendationControllerApi
 * @extends {BaseAPI}
 */
export class UprsRecommendationControllerApi extends BaseAPI {
  /**
   * Возвращает сумму рекомендации для автоплатежа и подсказку для этой суммы
   * @summary Получение рекомендации по сумме автоплатежа
   * @param {string} customerPhone Номер телефона в 11-значном формате
   * @param {string} [serviceId] Идентификатор ТСП, в пользу которого совершается платёж
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UprsRecommendationControllerApi
   */
  public getRecommendedAutopaymentAmount(customerPhone: string, serviceId?: string, options?: any) {
    return UprsRecommendationControllerApiFp(this.configuration).getRecommendedAutopaymentAmount(
      customerPhone,
      serviceId,
      options
    )(this.axios, this.basePath)
  }

  /**
   * Возвращает рекомендованную начальную дату для автоплатежа и подсказку для этой даты
   * @summary Получение рекомендации по дате автоплатежа
   * @param {string} customerPhone Номер телефона в 11-значном формате
   * @param {object} customerTimeZone Часовой пояс в формате +03:00
   * @param {string} [serviceId] Идентификатор ТСП, в пользу которого совершается платёж
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UprsRecommendationControllerApi
   */
  public getRecommendedAutopaymentDate(
    customerPhone: string,
    customerTimeZone: object,
    serviceId?: string,
    options?: any
  ) {
    return UprsRecommendationControllerApiFp(this.configuration).getRecommendedAutopaymentDate(
      customerPhone,
      customerTimeZone,
      serviceId,
      options
    )(this.axios, this.basePath)
  }

  /**
   * Возвращает рекомендованный лимит для автоплатежа и подсказку для этого лимита
   * @summary Получение рекомендации по лимиту автоплатежа
   * @param {string} customerPhone Номер телефона в 11-значном формате
   * @param {string} [serviceId] Идентификатор ТСП, в пользу которого совершается платёж
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UprsRecommendationControllerApi
   */
  public getRecommendedAutopaymentLimit(customerPhone: string, serviceId?: string, options?: any) {
    return UprsRecommendationControllerApiFp(this.configuration).getRecommendedAutopaymentLimit(
      customerPhone,
      serviceId,
      options
    )(this.axios, this.basePath)
  }

  /**
   * Возвращает сумму рекомендации для разового платежа и подсказку для этой суммы
   * @summary Получение рекомендации по сумме платежа
   * @param {string} customerPhone Номер телефона в 11-значном формате
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UprsRecommendationControllerApi
   */
  public getRecommendedPaymentAmount(customerPhone: string, options?: any) {
    return UprsRecommendationControllerApiFp(this.configuration).getRecommendedPaymentAmount(customerPhone, options)(
      this.axios,
      this.basePath
    )
  }

  /**
   * Возвращает сумму рекомендации для разового платежа и подсказку для этой суммы
   * @summary Получение рекомендации по сумме платежа для номера лицевого счёта
   * @param {string} customerAccount Номер лицевого счёта
   * @param {string} serviceId Идентификатор ТСП, в пользу которого совершается платёж
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UprsRecommendationControllerApi
   */
  public getRecommendedPaymentAmountByAccountNumber(customerAccount: string, serviceId: string, options?: any) {
    return UprsRecommendationControllerApiFp(this.configuration).getRecommendedPaymentAmountByAccountNumber(
      customerAccount,
      serviceId,
      options
    )(this.axios, this.basePath)
  }

  /**
   * Возвращает сумму рекомендации для разового платежа и подсказку для этой суммы
   * @summary Получение рекомендации по сумме платежа для номера телефона
   * @param {string} customerPhone Номер телефона в 11-значном формате
   * @param {string} serviceId Идентификатор ТСП, в пользу которого совершается платёж
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UprsRecommendationControllerApi
   */
  public getRecommendedPaymentAmountByPhoneNumber(customerPhone: string, serviceId: string, options?: any) {
    return UprsRecommendationControllerApiFp(this.configuration).getRecommendedPaymentAmountByPhoneNumber(
      customerPhone,
      serviceId,
      options
    )(this.axios, this.basePath)
  }
}
