import React, { FC } from 'react'
import { useRouter } from 'next/router'

import { OtpPcek } from '@components/OtpPcek'
import { TransferResponse } from '@open-api/ump/ewallet'
import { getResultPath } from '../utils/getResultPath'

interface OtpProps {
  mdOrder: string
}

export const OTP: FC<OtpProps> = ({ mdOrder }) => {
  const router = useRouter()
  const routerQueryId = router?.query.id
  const isWebView = router?.query?.isWebView
  const hasWebView = isWebView === 'true'

  const goToResultPage = () => {
    const path = getResultPath(mdOrder, hasWebView ? `?tsp=${routerQueryId}&isWebView=true` : `?tsp=${routerQueryId}`)

    router.push(path)
  }

  const handleError = (data: TransferResponse) => {
    if (data.errorCode === 107) {
      goToResultPage()
    }
  }

  return <OtpPcek mdOrder={mdOrder} onSuccess={goToResultPage} onError={handleError} />
}
