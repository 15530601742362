import React, { createRef, FC, RefObject, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import isEqual from 'lodash/isEqual';
import { Dictionary } from '@reduxjs/toolkit';
import { isEmpty, noop, pick, toNumber } from 'lodash';
import { useSelector } from 'react-redux';
import { Button, Spacer, Text, TextField as UIKitTextField, theme, useToast } from '@mtsbank/ui-kit';
import { FieldsProps, GetPaymentTermRequestData, WithoutAuthFormValues } from '@components/TspPayee/withoutAuthorization/type';
import { PaymentMethods } from '@components/PaymentMethodSelector/paymentDetector/AvailablePaymentMethodDetector';
import { chargeApi } from '@api/ump/charge-invoice';
import { Content } from '@components/Content/Content';
import { MoneyInputField } from '@components/FormFields/MoneyInputField';
import { CardData } from '@root/components/TspPayee/CardData/CardData';
import { configPaymentWithoutAuth } from '@components/TspPayee/config/configPaymentWithoutAuth';
import { FeeAndSum } from '@components/FeeAndSum/FeeAndSum';
import { BaseState } from '@root/types/reducers';
import { MESSAGE_FOR_COMMISSION } from '@components/TspPayee/utils/constants';
import { uuid } from '@root/utils/generateUuid';
import { ProviderBalanceAndMetersRequest, ProviderBalanceAndMetersResponse } from '@open-api/ump/charge-invoice';
import { Currency } from '@root/types/generated';
import { useLazyEffect } from '@root/hooks/useLazyEffect';
import { formatDate, parseDate } from '@root/utils/formatDate/formatDate';
import { ewalletApi } from '@root/api/ump/ewallet';
import { getPcekZone } from '@root/components/CardToCard/utils/helpers';
import { authHelper } from '@root/utils/authHelper/AuthHelper';
import { isNumber } from '@root/utils/format';
import { ServiceParams } from '@open-api/ump/ewallet';
import { selectBindings } from '@root/selectors/processing';
import { fieldsFocusBlurGtm, paymentButtonClickGtm } from '@root/utils/gtm/tsp/events';
import { SBER_TSP_ID, SberPayContext } from '@root/pages/perevod_v_sber';
import { FormatAliasPhone, formatPhoneNumber } from '@root/utils/formatPhoneNumber';
import { Param, Payee } from '@open-api/ump/catalog-manager';
import { CheckBoxField } from '@root/components/FormFields/CheckboxField';
import { TextField } from '@root/components/FormFields/TextField';
import { AmountRecommendationResponse } from '@open-api/ump/uprs-recommendation';
import { dpowerApi } from '@root/api/ump/dpower';
import { collectServiceParams } from '../utils/serviceUtils';
import { BalanceDetails } from '../BalanceDetails';
import { WrapperFooter, WrapperHint, WrapperSelector, Wrapper } from './styled';
import { BalanceInquiryForm } from '../topFields/BalanceInquiryForm';
import { MOBILE_PARAM_NAME, MTS_GROUP_PARAM } from '../constants';
import { PhoneTspField } from '../topFields/PhoneTspField';
import { getComissionOtherBankForMTS, isBinWithCommission, isMTS, isMtsInternetTv, isMtsPhone } from '../utils/helpers';
import { GCMtsFields } from '../topFields/GCMtsFields';
import { MtsPhoneFields } from '../topFields/MtsPhoneFields';
const {
  amountPaymentField,
  offerAgreement,
  fiscalEmailField,
  getTermHidden,
  currentBindingHidden,
  requestGetTermDataHidden,
  cardDataField,
  fiscalReceiptField
} = configPaymentWithoutAuth;
const BINDING_ID = {
  [PaymentMethods.GooglePay]: 'GOOGLE',
  [PaymentMethods.ApplePay]: 'APPLE',
  [PaymentMethods.SamsungPay]: 'SAMSUNG',
  [PaymentMethods.Card]: 'ANONYMOUS_CARD'
};
const DELAY = 800;
const GET_TERMS_ERRORS = {
  MAX_AMOUNT_ERROR: 40,
  EMPTY_AMOUNT: 91
};
const EXCLUDES_ERRORS_HANDLING = [GET_TERMS_ERRORS.MAX_AMOUNT_ERROR, GET_TERMS_ERRORS.EMPTY_AMOUNT];
const ignoreTermsErrorKeys = [fiscalEmailField.name];
export const PaymentFormFields: FC<FieldsProps> = ({
  fields = [],
  isLoadingPaymentDo,
  isCommissionOtherBankForMTS,
  serviceInfo,
  isAmountReadonly,
  setTermsData,
  setPaymentValues,
  setPayeeData,
  setIsCommissionMTSPay
}) => {
  const {
    values,
    submitForm,
    setFieldValue,
    setFieldTouched,
    errors,
    dirty
  } = useFormikContext<WithoutAuthFormValues>();
  const {
    userProfile,
    isAuthenticated
  } = authHelper;
  const userId = userProfile?.userId;
  const {
    balanceInquiryEnabled,
    invoiceSearchParams,
    serviceId,
    params,
    features,
    bindingTypes
  } = serviceInfo;
  const {
    paymentMethod,
    currentBinding,
    getTerm,
    validityPeriod,
    CVC,
    cardNumber,
    amount,
    requestGetTermData,
    paymentSource,
    fiscalReceipt
  } = values;
  const [userSberPay, setUserSberPay] = useState<string | null>(null);
  const [recommendedAmount, setRecommendedAmount] = useState<AmountRecommendationResponse>(null);
  const [mtsInternetTvPayee, setMtsInternetTvPayee] = useState<Payee>(null);
  const [fieldsList, setFieldsList] = useState(fields);
  const [balanceData, setBalanceData] = useState<ProviderBalanceAndMetersResponse>(null);
  const [balanceReqInProgress, setBalanceReqInProgress] = useState(false);
  const bindings = useSelector(selectBindings);
  const {
    toast
  } = useToast();
  const sberPayContext = useContext(SberPayContext);
  useEffect(() => {
    const isServiceMTS = !!features?.isMtsGroup;
    const selectedBinding = bindings?.find(({
      bindingId
    }) => bindingId === values.paymentSource);
    if (values?.cardNumber || selectedBinding?.maskedPan) {
      const isCommissionOtherBankForMTS = isBinWithCommission(selectedBinding?.maskedPan || values?.cardNumber);
      setIsCommissionMTSPay(!isCommissionOtherBankForMTS && isServiceMTS);
    }
  }, [values, serviceId, bindings]);
  useEffect(() => {
    if (recommendedAmount) {
      setFieldValue(amountPaymentField.name, recommendedAmount.amount);
    }
  }, [recommendedAmount]);
  const handlePaymentButtonClick = () => {
    paymentButtonClickGtm(serviceId);
    submitForm();
  };
  const handleBalanceRequestClick = () => {
    const invoicesKeys = invoiceSearchParams.map(param => param.name);
    const accountNumParamName = invoicesKeys.find(key => key.toLowerCase().includes('accountnumber'));
    const invoiceParamsValues = pick(values, invoicesKeys);
    if (!errors[accountNumParamName]) {
      setBalanceReqInProgress(true);
      chargeApi.getProviderBalanceWithCountersUsingPOST('mtsmon_site', ({
        requestId: uuid(),
        searchParams: {
          ...invoiceParamsValues
        }
      } as ProviderBalanceAndMetersRequest), {
        headers: {
          'client-id': 'mts-money-web-mtsid'
        }
      }).then(({
        data
      }) => {
        if (data.errorCode) {
          return Promise.reject(data);
        }
        const balanceData = data;
        setBalanceData(balanceData);
      }).catch(({
        data
      }) => {
        if (data?.errorMessage) {
          toast('error', 'Ошибка поиска баланса', (data.errorMessage as string) || '', {
            withClose: true,
            withTimeout: true,
            timeout: 3000
          });
        }
      }).finally(() => setBalanceReqInProgress(false));
    }
  };
  const fieldsRef = useRef<Dictionary<RefObject<HTMLInputElement | HTMLTextAreaElement>>>(fields.reduce((result, field) => {
    result[field.name] = createRef();
    return result;
  }, {}));
  const termsAllowed = useMemo(() => serviceId !== 'phone' && !Object.keys(errors).filter(key => !ignoreTermsErrorKeys.includes(key)).length, [errors, serviceId]);
  const paymentDatafilled = useMemo(() => values.amount && values[cardDataField.cardNumber.name] && values[cardDataField.CVCField.name] && values[cardDataField.validityPeriodField.name] || values.paymentSource, [values]);
  const fetchGetTerm = async (reqData: GetPaymentTermRequestData) => {
    setFieldValue(currentBindingHidden.name, {});
    setFieldValue(getTermHidden.name, {
      inProgress: true,
      hasError: false
    });
    const zone = getPcekZone(sberPayContext?.isAuthMTS);
    const {
      serviceId,
      amount,
      currency,
      serviceParams,
      expiry,
      cardholderName,
      bindingId,
      pan,
      cvc
    } = reqData;
    let options = sberPayContext?.headers;
    if (zone === 'anonymous') {
      options = {
        headers: {
          'client-Id': 'mts-money-web-mtsid',
          FhpSessionId: window.gibSessionId,
          FhpRequestId: window.gibRequestId
        }
      };
    }
    try {
      const response = await ewalletApi.getPaymentsTermsDo(zone, serviceId, (amount as number), currency, userId, bindingId, pan, expiry, cardholderName, cvc, (JSON.stringify(serviceParams) as ServiceParams), undefined, undefined, options);

      // TODO: Поправить типизацию в сваггере
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const currentBinding = response?.data?.bindings.find(bind => bind.userSelected);
      setTermsData(currentBinding);
      setPaymentValues(reqData);
      setFieldValue(currentBindingHidden.name, currentBinding, true);
      setFieldValue(getTermHidden.name, {
        inProgress: false,
        hasError: false
      });
    } catch (error) {
      if (!isEmpty(error.response?.data.bindings)) {
        const {
          bindings
        } = error.response.data;
        const currentBinding = bindings.find(bind => bind.bindingId.indexOf(BINDING_ID[paymentMethod]) !== -1);
        if (!isEmpty(currentBinding)) {
          setFieldValue(currentBindingHidden.name, currentBinding, true);
          setFieldValue(getTermHidden.name, {
            inProgress: false,
            hasError: false
          });
        }
      } else if (!EXCLUDES_ERRORS_HANDLING.includes(Number(error.response?.data?.error?.code))) {
        setFieldValue(getTermHidden.name, {
          inProgress: false,
          hasError: true
        });
      }
    }
  };
  useLazyEffect(() => {
    if (dirty && termsAllowed && paymentDatafilled) {
      const validityPeriodDate = parseDate(validityPeriod, '%m/%y');
      const expiry = formatDate(validityPeriodDate, 'YYYYMM');
      const cardData = {
        expiry,
        pan: cardNumber.replace(/\s/g, ''),
        cvc: CVC
      };
      let defaultParams: Param[];
      let id: string;
      if (mtsInternetTvPayee) {
        defaultParams = mtsInternetTvPayee.params;
        values[defaultParams[0].name] = values[MTS_GROUP_PARAM];
        id = mtsInternetTvPayee.serviceId;
      } else {
        defaultParams = params;
        id = serviceId;
      }
      const serviceParams = collectServiceParams(defaultParams || [], values, {
        onlyRequired: false
      });
      const currentRequestGetTermData = {
        serviceId: id,
        currency: Currency.RUB,
        amount: isNumber(values.amount) ? values.amount : toNumber((values.amount as string)?.replace(',', '.')),
        serviceParams,
        ...(!paymentSource && cardData),
        ...(paymentSource && {
          bindingId: paymentSource
        })
      };
      if (!isEqual(requestGetTermData, currentRequestGetTermData)) {
        setFieldValue(requestGetTermDataHidden.name, currentRequestGetTermData);
        fetchGetTerm(currentRequestGetTermData).then(noop);
      }
    }
  }, [amount, cardNumber, serviceInfo, dirty, paymentMethod, setFieldValue, validityPeriod, requestGetTermData, termsAllowed, paymentSource], DELAY);

  // TODO: Доработать ф-цию
  const linkClickSendStatistics = () => () => {};
  const offerAgreementValue = offerAgreement(linkClickSendStatistics());
  useEffect(() => {
    if (!isEqual(fields, fieldsList)) {
      setFieldsList(fields);
    }
  }, [fields, setFieldsList, fieldsList]);
  useEffect(() => {
    fieldsRef.current = fieldsList.reduce((result, field) => {
      result[field.name] = fieldsRef.current[field.name] || createRef();
      return result;
    }, {});
  }, [fieldsList]);
  useEffect(() => {
    if (window.location.href.includes('phone') && serviceInfo.params[0].name) {
      setFieldValue(serviceInfo.params[0].name, values[MOBILE_PARAM_NAME]);
    }
  }, [serviceInfo]);
  const isBanalceCheckShow = useMemo(() => isAuthenticated() && balanceInquiryEnabled && !serviceInfo?.features?.isMtsGroup, [isAuthenticated, balanceInquiryEnabled, serviceInfo]);
  const feeCommission = isCommissionOtherBankForMTS ? getComissionOtherBankForMTS(toNumber(currentBinding?.amount?.base?.toString().replace(',', '.'))) : currentBinding?.amount?.fee;
  const handleAmountFocusBlur = () => {
    fieldsFocusBlurGtm(serviceId, amountPaymentField.label);
  };
  const fiscalReceiptOnChange = () => {
    setFieldTouched(fiscalReceiptField.name, true);
    setFieldValue(fiscalReceiptField.name, !values.fiscalReceipt);
  };
  const renderFirstField = field => {
    if (isBanalceCheckShow) {
      return <BalanceInquiryForm isLoading={balanceReqInProgress || getTerm.inProgress} onClick={handleBalanceRequestClick} field={field} componentRef={fieldsRef.current[field.name]} />;
    }
    if (window.location.href.includes('phone')) {
      return <PhoneTspField setRecommendedAmount={setRecommendedAmount} setPayeeData={setPayeeData} />;
    }
    if (isMtsPhone(serviceId)) {
      return <MtsPhoneFields field={field} setRecommendedAmount={setRecommendedAmount} />;
    }
    if (isMtsInternetTv(serviceId)) {
      return <GCMtsFields setRecommendedAmount={setRecommendedAmount} setMtsInternetTvPayee={setMtsInternetTvPayee} field={field} serviceId={serviceId} />;
    }
    return <Field {...field} size="lg" componentRef={fieldsRef?.current?.[field.name]} />;
  };
  useEffect(() => {
    if (serviceId === SBER_TSP_ID) {
      const phoneNumber = fieldsRef?.current?.id1?.current?.value;
      if (formatPhoneNumber(phoneNumber)) {
        dpowerApi.sberCheckPhone(formatPhoneNumber(phoneNumber, FormatAliasPhone.DIGIT11)).then(res => {
          if (res?.data?.customerName) {
            setUserSberPay(res?.data?.customerName);
          } else {
            setUserSberPay(null);
            toast('error', 'Ошибка', 'Перевод по этому номеру телефона в СБЕР недоступен', {
              withClose: true,
              withTimeout: true,
              timeout: 3000
            });
          }
        }).catch(() => {
          setUserSberPay(null);
        });
      } else {
        setUserSberPay(null);
      }
    }
  }, [fieldsRef?.current?.id1?.current?.value, serviceId, toast]);
  return <Wrapper>
      {fieldsList.map((field, index) => <Content marginBottom={theme.spacings.xs} key={`field-wrapper_${field.name}_${serviceInfo?.serviceId}`}>
          {index === 0 ? renderFirstField(field) : <Field {...field} size="lg" componentRef={fieldsRef.current[field.name]} />}
        </Content>)}

      {serviceId === SBER_TSP_ID && userSberPay && <>
          <UIKitTextField disabled label="Получатель" value={userSberPay} />
          <Spacer space={16} spacemob={16} />
        </>}

      {!balanceReqInProgress && balanceData && <BalanceDetails balanceData={balanceData} />}
      <>
        <Content marginBottom={theme.spacings.xs}>
          <Field onFocus={handleAmountFocusBlur} onBlur={handleAmountFocusBlur} component={MoneyInputField} {...amountPaymentField} readOnly={isAmountReadonly} size="lg" hint={recommendedAmount?.hint} />
        </Content>
        <WrapperSelector>
          <CardData bindingTypes={bindingTypes} />
        </WrapperSelector>
        {!!features?.isMtsGroup && <>
            <Content marginBottom={theme.spacings.md}>
              <Field component={CheckBoxField} name={fiscalReceiptField.name} onChange={fiscalReceiptOnChange}>
                <Text sizemob="md">{fiscalReceiptField.text}</Text>
              </Field>
            </Content>
            {fiscalReceipt && <Content marginBottom={theme.spacings.md}>
                <Field component={TextField} {...fiscalEmailField} />
              </Content>}
          </>}
        <Content marginBottom={theme.spacings.md}>
          <FeeAndSum fee={feeCommission} base={currentBinding?.amount?.base} inProgress={!currentBinding?.amount && (values[getTermHidden.name] as BaseState<unknown>).inProgress} isInitialProgress={!currentBinding?.amount} message={isCommissionOtherBankForMTS && MESSAGE_FOR_COMMISSION} />
        </Content>
        <WrapperFooter>
          <WrapperHint>
            <Text color={theme.colors.neutral.g300} size="md">
              {offerAgreementValue}
            </Text>
          </WrapperHint>
          <Button size="lg" isLoading={isLoadingPaymentDo || getTerm.inProgress} onClick={handlePaymentButtonClick} disabled={!userSberPay && serviceId === SBER_TSP_ID}
        // disabled={!isValid || !dirty || !serviceInfo?.providerName}
        >
            Оплатить
          </Button>
        </WrapperFooter>
      </>
    </Wrapper>;
};