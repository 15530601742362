import React, { FC } from 'react'
import isNumber from 'lodash/isNumber'
import { CurrencySymbolsType, formatMoney, Text, theme } from '@mtsbank/ui-kit'

import { currencyMapping } from '@root/constants/currencyMapping'
import { Binding } from '@open-api/ump/ewallet-profile'
import { BindingIcon } from '../BindingIcon/BindingIcon'
import {
  IconWrapper,
  InnerLabelWrapper,
  LabelWrapper,
  OptionItemWrapper,
  TextMaskedPan,
  TextBalance,
  TextMnemonic,
  Content,
  WrapperTitle,
} from './styles'

interface OptionItemProps {
  binding: Binding
  clearable?: boolean
  isInMobileContainer?: boolean
  label?: string
}

export const OptionItem: FC<OptionItemProps> = ({ binding, clearable, isInMobileContainer, label }) => {
  const amountBalance = Number(binding?.balance)
  const isBalanceAvailable = binding?.balance && isNumber(amountBalance)
  const balanceStr = isBalanceAvailable
    ? formatMoney(binding.balance, currencyMapping[binding?.currency] as CurrencySymbolsType)
    : null

  const mnemonicHasPan = binding?.mnemonic?.indexOf('*') > 0

  return (
    <>
      {label && (
        <InnerLabelWrapper>
          <Text color={theme.colors.neutral.g300} size="md" sizemob="sm" style={{ marginTop: -2, marginBottom: 2 }}>
            {label}
          </Text>
        </InnerLabelWrapper>
      )}
      <OptionItemWrapper clearable={clearable}>
        <IconWrapper>
          <BindingIcon binding={binding} />
        </IconWrapper>

        <LabelWrapper style={{ marginTop: label ? 18 : undefined }} isInMobileContainer={isInMobileContainer}>
          <Content>
            <WrapperTitle>
              <TextMnemonic sizemob="md" isInMobileContainer={isInMobileContainer}>
                {mnemonicHasPan ? binding?.mnemonic?.slice(0, -5) : binding?.mnemonic}
              </TextMnemonic>

              {binding?.maskedPan && '\u00b7\u00b7'}

              <TextMaskedPan color={theme.colors.black} sizemob="md">
                {binding?.mnemonic?.indexOf('·') < 0 ? binding?.maskedPan?.substr(-4, 4) : ''}
              </TextMaskedPan>
            </WrapperTitle>

            {balanceStr && (
              <TextBalance color={theme.colors.black} size="lg" sizemob="md">
                {balanceStr}
              </TextBalance>
            )}
          </Content>
        </LabelWrapper>
      </OptionItemWrapper>
    </>
  )
}
