import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FieldProps } from 'formik'
import debounce from 'lodash.debounce'
import axios, { AxiosResponse } from 'axios'
import { CardInput, InputHelperText, TextFieldProps as KitTextFieldProps } from '@mtsbank/ui-kit'
import { CardInfoRequest, CardInfoRequestStatusDescriptionEnum, CardInfoResponse } from '@open-api/ump/payments-hub'
import { InputErrorMessage } from '@components/InputErrorMessage/InputErrorMessage'
import { getCardType } from '@utils/creditCardType/lib/getCardType'
import { CardType } from '@utils/creditCardType/type'

export const cardIcons = {
  [CardType.MASTERCARD]: 'payment/masterCard',
  [CardType.VISA]: 'payment/visa',
  [CardType.MAESTRO]: 'payment/maestro',
  [CardType.MIR]: 'payment/mir',
  [CardType.UNIONPAY]: 'payment/unionPay',
}

export interface Props extends FieldProps, KitTextFieldProps {
  hint: string
  className?: string
}

export const CardInputField: FC<Props> = ({
  field,
  form: { touched, errors, setFieldTouched, setFieldValue, handleBlur },
  onChange,
  hint,
  className,
  maskBlurred,
  onFocus,
  onBlur,
  ...rest
}) => {
  const touchedItem = touched[field.name]
  const errorItem = errors[field.name]

  const [hasError, setHasError] = useState<boolean>(touchedItem && Boolean(errorItem))
  const setHasErrorDebounced = useMemo(
    () =>
      debounce((hasError: boolean) => setHasError(hasError), 100, {
        leading: false,
        trailing: true,
      }),
    [setHasError]
  )
  const [icon, setIcon] = useState(null)
  const [isFocused, setFocused] = useState(false)
  const iconAxiosRequest = useRef(null)
  const iconAxiosRequestAbortController = useRef(new AbortController())

  const defaultIconSet = useCallback((cardNumber: string) => {
    if (cardNumber.length > 0 && getCardType(cardNumber)) {
      setIcon(cardIcons[getCardType(cardNumber)])
    } else {
      setIcon(null)
    }
  }, [])

  const updateIcon = useCallback(
    debounce(
      (value: string) => {
        if (iconAxiosRequest.current) {
          iconAxiosRequestAbortController.current.abort()
          iconAxiosRequestAbortController.current = new AbortController()
        }
        iconAxiosRequest.current = axios
          .post<CardInfoRequest, AxiosResponse<CardInfoResponse>>(
            '/spa/api/check-cards/getCardInfo',
            {
              bin: parseInt((value.replace(/\D/g, '') || '').slice(0, 6), 10),
              number: value,
            },
            {
              signal: iconAxiosRequestAbortController.current.signal,
            }
          )
          .then((response) => {
            const {
              status: { description: iconStatusDescription },
              paymentSystem,
            } = response.data

            if (
              iconStatusDescription === CardInfoRequestStatusDescriptionEnum.Success &&
              paymentSystem &&
              cardIcons[paymentSystem.toLowerCase()]
            ) {
              setIcon(cardIcons[paymentSystem.toLowerCase()])
            } else {
              defaultIconSet(value)
            }
          })
          .catch(() => {
            defaultIconSet(value)
          })
      },
      150,
      {
        leading: false,
        trailing: true,
      }
    ),
    []
  )
  const handleChangeDebounced = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event)
      } else {
        if (event.target.value.length > 6) {
          updateIcon(event.target.value)
        } else {
          defaultIconSet(event.target.value)
        }
        setFieldValue(field.name, event.target.value)
      }

      setFieldTouched(field.name, true, false)
    },
    [field.name, onChange, setFieldTouched, setFieldValue, defaultIconSet]
  )

  const handleClear = useCallback(() => {
    setIcon(null)
    setFieldValue(field.name, '')
    setFieldTouched(field.name, true, false)
  }, [field.name, setFieldTouched, setFieldValue])

  const onFocusBlur = useCallback(
    (event) => {
      const focused = event.type === 'focus'

      setFocused(focused)

      if (typeof onFocus !== 'undefined' && focused) {
        onFocus(event)
      }

      if (!focused) {
        handleBlur(event)
        if (typeof onBlur !== 'undefined' && !focused) {
          onBlur(event)
        }
      }
    },
    [onFocus, onBlur, handleBlur]
  )

  useEffect(() => {
    setHasErrorDebounced(touchedItem && Boolean(errorItem))
  }, [touchedItem, errorItem, setHasErrorDebounced])

  return (
    <div className={className}>
      <CardInput
        {...field}
        {...rest}
        hasError={hasError}
        onClear={handleClear}
        onChange={handleChangeDebounced}
        icon={icon}
        onFocus={onFocusBlur}
        onBlur={onFocusBlur}
        mask={isFocused || !maskBlurred ? rest.mask : maskBlurred}
      />
      {hint && !hasError && <InputHelperText>{hint}</InputHelperText>}
      {hasError && <InputErrorMessage name={field.name} />}
    </div>
  )
}
