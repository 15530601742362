import React, { FC, Ref, RefObject, useCallback, useEffect, useRef, useState } from 'react'
import Inputmask from 'inputmask'
import { FieldInputProps } from 'formik/dist/types'
import { useIsomorphicLayoutEffect } from '@mtsbank/ui-kit'

import { TextField, TextFieldProps } from '@components/FormFields/TextField'
import { fieldsFocusBlurGtm } from '@root/utils/gtm/tsp/events'
import { getDeviceType } from '@root/utils/getPlatorm'

type Props = {
  serviceId: string
  field: {
    defaultValue: string
  } & FieldInputProps<string>
  mask:
    | string
    | {
        mask: string
        regex: RegExp
      }
} & TextFieldProps

export const InputMaskTextField: FC<Props> = ({ onFocus, onBlur, mask, field, serviceId, ...rest }) => {
  const { setFieldValue, setFieldTouched } = rest.form
  const { label } = rest
  const [inputMaskLoaded, setInputMaskLoaded] = useState(false)

  const isInitiating = useRef(true)
  let componentRef: Ref<HTMLInputElement | HTMLTextAreaElement> = useRef<HTMLInputElement>(null)
  const InputMask = useRef<Inputmask.Static>(null)

  const fieldName: string = field.name

  if (rest.componentRef && rest.componentRef !== componentRef) {
    componentRef = rest.componentRef
  }

  const inputRef = (componentRef as RefObject<HTMLInputElement | HTMLTextAreaElement>).current
  const refInputMask = inputRef?.inputmask

  useIsomorphicLayoutEffect(() => {
    import('inputmask').then((inputmask) => {
      InputMask.current = inputmask.default
      setInputMaskLoaded(true)
    })
  })

  useEffect(() => {
    if (inputRef && inputMaskLoaded && mask && isInitiating.current) {
      const inputMask = InputMask.current(mask as string)

      if (getDeviceType() === 'ios') {
        if (field.value || mask.mask) {
          inputMask.mask(inputRef)
        }
      } else {
        inputMask.mask(inputRef)
      }

      if (isInitiating.current) {
        isInitiating.current = false
      }
    }
  }, [InputMask, inputRef, refInputMask, mask, inputMaskLoaded])

  const handleChangeDebounced = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target

      setFieldValue(fieldName, value)
      setFieldTouched(fieldName, false, false)
    },
    [setFieldTouched, setFieldValue, fieldName]
  )

  const onFocusBlur = useCallback(
    (event) => {
      fieldsFocusBlurGtm(serviceId, label)
      const focused = event.type === 'focus'

      if (typeof onFocus !== 'undefined' && focused) {
        onFocus(event)
      }

      if (!focused) {
        if (field.onBlur) {
          field.onBlur(event)
        }

        if (typeof onBlur !== 'undefined' && !focused) {
          onBlur(event)
        }
      }
    },
    [onFocus, onBlur]
  )

  return (
    <TextField
      field={field}
      {...rest}
      componentRef={componentRef}
      onChange={handleChangeDebounced}
      onBlur={onFocusBlur}
      onFocus={onFocusBlur}
    />
  )
}
