import React, { FC, useContext } from 'react';
import { useSelector } from 'react-redux';
import { AxiosError } from 'axios';
import { ConfirmationField, Flex, Heading, Text, theme } from '@mtsbank/ui-kit';
import { ConfirmTransferByOtpRq, ReSendOtpRq, TransferResponse } from '@open-api/ump/ewallet';
import { UnicodeSymbols } from '@root/types';
import { useAppThunkDispatch } from '@root/store';
import { selectOtpPcek } from '@selectors/selectOtpPcek';
import { confirmTransferByOtp, reSendOtp } from '@reducers/otpPcek/otpPcek';
import { Content } from '@components/Content/Content';
import { authHelper } from '@utils/authHelper/AuthHelper';
import { formatPhoneNumber } from '@utils/formatPhoneNumber';
import { SberPayContext } from '@root/pages/perevod_v_sber';
import { FieldWrapper } from './styled';
const TOP_TEXT_DEFAULT = 'Код подтверждения отправлен';
interface OtpPcekProps {
  mdOrder: string;
  onSuccess?: (val?: TransferResponse) => void;
  onError?: (val?: TransferResponse) => void;
}
export const OtpPcek: FC<OtpPcekProps> = ({
  mdOrder,
  onSuccess,
  onError
}) => {
  const sberPayContext = useContext(SberPayContext);
  const {
    userProfile,
    isAuthenticated
  } = authHelper;
  const userId = userProfile?.userId;
  const zone = sberPayContext?.isAuthMTS || isAuthenticated() ? 'b' : 'anonymous';
  const dispatch = useAppThunkDispatch();
  const {
    inProgress,
    error
  } = useSelector(selectOtpPcek);
  const handleComplete = (otp: string, showErr: (text: string) => void) => {
    const params: ConfirmTransferByOtpRq = {
      userId,
      mdOrder,
      otp
    };
    dispatch(confirmTransferByOtp({
      zone,
      options: {
        headers: sberPayContext?.headers
      },
      ...params
    })).unwrap().then(data => {
      if (!data?.errorCode) {
        onSuccess?.(data);
      } else {
        showErr(data?.errorMessage);
        onError?.(data);
      }
    }).catch((error: AxiosError) => {
      const message = error?.message;
      showErr(message);
    });
  };
  const handleSendCode = () => {
    const params: ReSendOtpRq = {
      userId,
      mdOrder
    };
    dispatch(reSendOtp({
      zone,
      options: {
        headers: sberPayContext?.headers
      },
      ...params
    }));
  };
  const topText = userProfile?.mobilePhone ? `${TOP_TEXT_DEFAULT} на${UnicodeSymbols.SPACE}номер ${formatPhoneNumber(userProfile?.mobilePhone)}` : TOP_TEXT_DEFAULT;
  return <Flex direction="column" alignItems="center">
      <Heading align="center" h={3}>
        Подтверждение
      </Heading>
      <Text align="center">{topText}</Text>
      <Content marginTop={theme.spacings.md}>
        <Text>Код из&nbsp;СМС</Text>
      </Content>

      <FieldWrapper>
        <ConfirmationField autoFocus isErrorAutoClearEnabled hasError={Boolean(error?.message)} errorMessage={error?.message} isPhoneEditable={false} fieldsCount={5} isLoading={inProgress} onComplete={handleComplete} onSendCodeClick={handleSendCode} />
      </FieldWrapper>
    </Flex>;
};