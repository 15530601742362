import React, { FC, useEffect } from 'react';
import { Text, useWindowSize } from '@mtsbank/ui-kit';
import { bannerClickGtm, bannerShowGtm } from '@root/utils/gtm/tsp/events';
import { BannerContainer, TextContainer, Image, BannerWrapper, Button } from './PromotionBanner.styled';
export const PromotionBanner: FC = () => {
  const isTablet = useWindowSize().width >= 600;
  const link = 'https://mtsdengi.ru/karti/debet-mts-dengi-virtual/?utm_source=inhouse&utm_medium=payments&utm_content=oplata&utm_campaign=mts_dengi_anonym';
  const handleClick = () => {
    bannerClickGtm('banner_mtsd_card_lite');
  };
  useEffect(() => {
    bannerShowGtm('banner_mtsd_card_lite');
  }, []);
  return <BannerWrapper>
      <BannerContainer href={link} target="_blank" onClick={handleClick}>
        <TextContainer>
          <Text size="lg" sizemob="md" fontWeight="medium" color="white">
            50 ГБ интернета и&nbsp;кешбэк&nbsp;30% на связь
          </Text>
          <Text size="md" sizemob="md" color="white" style={{
          marginTop: '4px'
        }}>
            {isTablet ? 'С виртуальной картой МТС Деньги Лайт' : 'С картой МТС Деньги Лайт'}
          </Text>
          <Button>
            <Text size="md" fontWeight="medium">
              Получить карту
            </Text>
          </Button>
        </TextContainer>
        <Image src="https://staticpayment.ssl.mts.ru/img/lewisCardPayment.webp" alt="Карта МТС Деньги" aria-hidden="true" />
      </BannerContainer>
    </BannerWrapper>;
};