import React, { FC, useEffect, useMemo, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { debounce, isEmpty } from 'lodash';
import { MoneyInput, Text, TextField, theme } from '@mtsbank/ui-kit';
import { FieldProps, WithoutAuthFormValues } from '@components/TspPayee/withoutAuthorization/type';
import { uuid } from '@root/utils/generateUuid';
import { getPcekZone } from '@root/components/CardToCard/utils/helpers';
import { catalogManagerApi } from '@root/api/ump/catalog-manager';
import { Payee } from '@open-api/ump/catalog-manager';
import { Content } from '@root/components/Content/Content';
import { custApi } from '@root/api/ump/cust';
import { uprsRecommendationApi } from '@root/api/ump/uprs-recommendation';
import { Policies } from '@open-api/ump/cust';
import { AmountRecommendationResponse } from '@open-api/ump/uprs-recommendation';
import { authHelper } from '@root/utils/authHelper/AuthHelper';
import { MTS_GROUP_PARAM, STATUS_DISABLED_ERROR } from '../../constants';
import { configPaymentWithoutAuth } from '../../config/configPaymentWithoutAuth';
import { isMtsInternetTv } from '../../utils/helpers';
import { FieldWrapper, FieldsWrapper } from './styled';
interface Props {
  field: FieldProps;
  serviceId: string;
  setMtsInternetTvPayee: (payee: Payee) => void;
  setRecommendedAmount: (recomendation: AmountRecommendationResponse) => void;
}
const scope = 'ALL';
export const GCMtsFields: FC<Props> = ({
  field,
  serviceId,
  setMtsInternetTvPayee,
  setRecommendedAmount
}) => {
  const {
    values,
    errors
  } = useFormikContext<WithoutAuthFormValues>();
  const {
    mtsGroupSelectionError
  } = configPaymentWithoutAuth;
  const zone = getPcekZone();
  const {
    isAuthenticated
  } = authHelper;
  const isAuth = isAuthenticated();
  let optionsAnonymous;
  if (zone === 'anonymous') {
    optionsAnonymous = {
      headers: {
        'client-Id': 'mts-money-web-mtsid'
      }
    };
  }
  const numberParam = (values[MTS_GROUP_PARAM] as string)?.replaceAll('_', '') || '';
  const payAccountError = errors[MTS_GROUP_PARAM] || numberParam?.length < 9;
  const [serviceIdByPayAccount, setServiceIdByPayAccount] = useState<string>(null);
  const [policies, setPolicies] = useState<Policies>(null);
  const [serviceByPayAccountError, setServiceByPayAccountError] = useState(null);
  const [service, setService] = useState<Payee>(null);
  const [serviceError, setServiceError] = useState(null);
  const [isDisabledStatus, setIsDisabledStatus] = useState(false);
  const [currentBalance, setCurrentBalance] = useState(null);
  const operatorIsNotDefinedError = !(isEmpty(serviceByPayAccountError) && isEmpty(serviceError));
  useEffect(() => {
    if (isMtsInternetTv(serviceId) && !payAccountError) {
      debounceGetPaymentService((values[MTS_GROUP_PARAM] as string));
    }
  }, [values?.NUMBER, errors, serviceId, payAccountError]);
  const debounceGetPaymentService = debounce((value: string) => {
    custApi.getPolicies('b', uuid(), value, scope, optionsAnonymous).then(({
      data
    }) => {
      // eslint-disable-next-line no-underscore-dangle
      const policies = data?._embedded?.policies[0];
      const serviceInfo = policies?.ewPayPolicies[0];
      if (serviceInfo) {
        if (serviceInfo.status === 'ENABLED') {
          // TODO: править сваггер каста для адекватного интерфейса EwPayPolicies
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setServiceIdByPayAccount((serviceInfo.service.id as string));
          setPolicies(policies);
          setServiceByPayAccountError(null);
          setIsDisabledStatus(false);
        } else {
          setIsDisabledStatus(true);
        }
      } else {
        setServiceByPayAccountError(data);
      }
    }).catch(error => setServiceByPayAccountError(error));
    if (isAuth) {
      custApi.getBalance(zone, uuid(), (values[MTS_GROUP_PARAM] as string), scope, optionsAnonymous)
      // eslint-disable-next-line no-underscore-dangle
      .then(({
        data
      }) => setCurrentBalance(data?._embedded?.balances[0]?.accBalances[0]?.total?.amount || null)).catch(() => setCurrentBalance(null));
    }
  }, 700, {
    leading: false,
    trailing: true
  });
  useEffect(() => {
    if (payAccountError) {
      setCurrentBalance(null);
    }
  }, [payAccountError]);
  const fetchPayeeData = (id: string) => new Promise((res, rej) => {
    catalogManagerApi.getPayees(zone, uuid(), id, '', true, true, optionsAnonymous).then(result => {
      res(result.data.payees[0]);
    }).catch(error => rej(error));
  });
  useEffect(() => {
    if (serviceIdByPayAccount) {
      fetchPayeeData(serviceIdByPayAccount).then((payeeData: Payee) => {
        setServiceError(null);
        setService(payeeData);
        setMtsInternetTvPayee(payeeData);
      }).catch(e => setServiceError(e));
    }
  }, [serviceIdByPayAccount]);
  useEffect(() => {
    if (service && policies) {
      if (policies.customer?.phone) {
        const {
          phone
        } = policies.customer;
        uprsRecommendationApi.getRecommendedPaymentAmount(phone, optionsAnonymous).then(({
          data
        }) => {
          if (data.amount) {
            setRecommendedAmount(data);
          }
        });
      }
      if (policies.customer?.account) {
        const {
          account
        } = policies.customer;
        uprsRecommendationApi.getRecommendedPaymentAmountByAccountNumber(account, service.serviceId, optionsAnonymous).then(({
          data
        }) => {
          if (data.amount) {
            setRecommendedAmount(data);
          }
        });
      }
    }
  }, [service, policies]);
  const isShowError = useMemo(() => operatorIsNotDefinedError || isDisabledStatus, [operatorIsNotDefinedError, isDisabledStatus]);
  return <>
      <FieldsWrapper>
        <FieldWrapper>
          <Field {...field} size="lg" />
        </FieldWrapper>
        {isAuth && <FieldWrapper>
            <Field allowDecimal readOnly value={currentBalance} component={MoneyInput} label="Текущий баланс" />
          </FieldWrapper>}
      </FieldsWrapper>
      {isShowError && <Content marginBottom={theme.spacings.xs} marginTop={theme.spacings.xs3}>
          <Text sizemob="md" color={theme.colors.red.active}>
            {isDisabledStatus ? STATUS_DISABLED_ERROR : mtsGroupSelectionError}
          </Text>
        </Content>}
    </>;
};