import React, { FC } from 'react';
import { Param } from '@open-api/ump/catalog-manager';
import { EWCharge } from '@open-api/ump/charge-invoice';
import { Charge } from '../Charge';
import { ChargesWrapper } from './styled';
interface Props {
  paymentParams: Param[];
  charges: EWCharge[];
  setSelectedCharge: (charge: EWCharge) => void;
  updateChargesResult: () => void;
}
export const ChargesList: FC<Props> = ({
  paymentParams,
  charges,
  setSelectedCharge,
  updateChargesResult
}) => <ChargesWrapper>
    {charges.map((charge, idx) => <Charge key={idx} updateChargesResult={updateChargesResult} setSelectedCharge={setSelectedCharge} paymentParams={paymentParams} charge={charge} />)}
  </ChargesWrapper>;