import React, { FC, useEffect } from 'react'
import { Payee } from '@open-api/ump/catalog-manager'
import { GroupIBService } from '@root/utils/groupIBService'
import { EGibTypes } from '@root/constants'
import { PaymentForm } from '../../PaymentForm/PaymentForm'
import { ChargesForm } from '../../ChargesForm/ChargesForm'

interface Props {
  serviceInfo: Payee
  setMdOrder: (mdOrder: string) => void
  setIsOtp: (isShowOtp: boolean) => void
  setPayeeData: (payeeData: Payee) => void
}

export const FormikHandlerWithoutAuth: FC<Props> = ({ serviceInfo, setIsOtp, setMdOrder, setPayeeData }) => {
  const { searchInvoicesEnabled } = serviceInfo || {}

  useEffect(() => {
    // eslint-disable-next-line no-new
    new GroupIBService({ type: EGibTypes.PAYMENT })
  }, [])

  return (
    <>
      {searchInvoicesEnabled ? (
        <ChargesForm setIsOtp={setIsOtp} setMdOrder={setMdOrder} serviceInfo={serviceInfo} />
      ) : (
        <PaymentForm
          setPayeeData={setPayeeData}
          setIsOtp={setIsOtp}
          setMdOrder={setMdOrder}
          serviceInfo={serviceInfo}
        />
      )}
    </>
  )
}
