import * as Yup from 'yup'
import { messages } from '@root/utils/formValidators/messages'
import { validationRules } from '@root/utils/formValidators/rules'
import { messagesTooltip } from '@components/TspPayee/config/messagesTooltip'

const { required } = messages
const { minPhoneNumber } = validationRules

export const configLoginForm = {
  loginPhoneNumberField: {
    name: 'loginPhoneNumber',
    isClearable: true,
    label: 'Номер телефона',
    tooltip: messagesTooltip.phone,
    placeholder: '+7 000 000-00-00',
  },
  informationHint: {
    name: 'showHintInformationWindow',
    text: 'Пополняйте баланс любого мобильного оператора, создавайте шаблоны и\u00A0получайте кешбэк за\u00A0привычные платежи',
  },
  initialValues: {
    loginPhoneNumber: '',
    showHintInformationWindow: true,
  },
  validationSchema: Yup.object().shape({
    loginPhoneNumber: Yup.string().min(minPhoneNumber, required),
  }),
}
