import React, { FC } from 'react';
import { Money, Text, theme } from '@mtsbank/ui-kit';
import { Cell } from '@mtsbank/common';
import { ProviderBalanceAndMetersResponse } from '@open-api/ump/charge-invoice';
import { pennyToRubles } from '../utils/helpers';
import { BalanceDetailsWrapper } from './styled';
interface Props {
  balanceData: ProviderBalanceAndMetersResponse;
}
export const BalanceDetails: FC<Props> = ({
  balanceData
}) => <BalanceDetailsWrapper>
    <Cell rowStart={1} rowEnd={1} columnEnd={1} columnStart={1}>
      <Text color={theme.colors.neutral.g300}>Текущий баланс</Text>
    </Cell>
    <Cell rowStart={1} rowEnd={1} columnEnd={2} columnStart={2}>
      <Money size="lg" value={pennyToRubles(balanceData.balanceAmount)} currency="RUB" />
    </Cell>
    <Cell rowStart={2} rowEnd={2} columnEnd={1} columnStart={1}>
      <Text color={theme.colors.neutral.g300}>Адрес</Text>
    </Cell>
    <Cell rowStart={2} rowEnd={2} columnEnd={2} columnStart={2}>
      <Text>{balanceData.clientInfo?.address}</Text>
    </Cell>
    <Cell rowStart={3} rowEnd={3} columnEnd={1} columnStart={1}>
      <div>
        <Text color={theme.colors.neutral.g300}>Информация</Text>
        <Text color={theme.colors.neutral.g300} size="md">{`Показания учтенные в\u00A0последнем счете`}</Text>
      </div>
    </Cell>
    <Cell rowStart={3} rowEnd={3} columnEnd={2} columnStart={2}>
      {balanceData.balanceDetails?.map(item => <Text key={item.name}>{item.name}</Text>)}
    </Cell>
  </BalanceDetailsWrapper>;