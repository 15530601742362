class FormUtils {
  public static createForm(url: string) {
    const formElement = document.createElement('form')

    formElement.setAttribute('style', 'display:none')
    formElement.action = url
    formElement.method = 'POST'

    return formElement
  }

  public static createField(name: string, value: string) {
    const inputElement = document.createElement('input')

    inputElement.setAttribute('name', name)
    inputElement.setAttribute('value', value)
    inputElement.setAttribute('type', 'hidden')

    return inputElement
  }
}

export default FormUtils
