// tslint:disable
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url'
import { Configuration } from './configuration'
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base'

/**
 *
 * @export
 * @interface SberCheckPhoneRes
 */
export interface SberCheckPhoneRes {
  /**
   *
   * @type {number}
   * @memberof SberCheckPhoneRes
   */
  ErrCode?: number
  /**
   *
   * @type {string}
   * @memberof SberCheckPhoneRes
   */
  Description?: string
  /**
   *
   * @type {string}
   * @memberof SberCheckPhoneRes
   */
  customerName?: string
}

/**
 * ControllerApi - axios parameter creator
 * @export
 */
export const ControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} phone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sberCheckPhone(phone: string, options: any = {}): RequestArgs {
      // verify required parameter 'phone' is not null or undefined
      if (phone === null || phone === undefined) {
        throw new RequiredError('phone', 'Required parameter phone was null or undefined when calling sberCheckPhone.')
      }
      const localVarPath = `/b/dpower/info/sber/checkPhone`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (phone !== undefined) {
        localVarQueryParameter['Phone'] = phone
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ControllerApi - functional programming interface
 * @export
 */
export const ControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} phone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sberCheckPhone(
      phone: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SberCheckPhoneRes> {
      const localVarAxiosArgs = ControllerApiAxiosParamCreator(configuration).sberCheckPhone(phone, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * ControllerApi - factory interface
 * @export
 */
export const ControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @param {string} phone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sberCheckPhone(phone: string, options?: any): AxiosPromise<SberCheckPhoneRes> {
      return ControllerApiFp(configuration).sberCheckPhone(phone, options)(axios, basePath)
    },
  }
}

/**
 * ControllerApi - object-oriented interface
 * @export
 * @class ControllerApi
 * @extends {BaseAPI}
 */
export class ControllerApi extends BaseAPI {
  /**
   *
   * @param {string} phone
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ControllerApi
   */
  public sberCheckPhone(phone: string, options?: any) {
    return ControllerApiFp(this.configuration).sberCheckPhone(phone, options)(this.axios, this.basePath)
  }
}
