// tslint:disable
/**
 * payments-hub-gateway
 * MTS Bank / MTS IT
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url'
import { Configuration } from './configuration'
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base'

/**
 *
 * @export
 * @interface CardInfoRequest
 */
export interface CardInfoRequest {
  /**
   *
   * @type {number}
   * @memberof CardInfoRequest
   */
  bin: number
  /**
   *
   * @type {string}
   * @memberof CardInfoRequest
   */
  number: string
}
/**
 *
 * @export
 * @interface CardInfoRequestStatus
 */
export interface CardInfoRequestStatus {
  /**
   *
   * @type {number}
   * @memberof CardInfoRequestStatus
   */
  code?: number
  /**
   *
   * @type {string}
   * @memberof CardInfoRequestStatus
   */
  description?: CardInfoRequestStatusDescriptionEnum
}

/**
 * @export
 * @enum {string}
 */
export enum CardInfoRequestStatusDescriptionEnum {
  Success = 'Success',
  Error = 'Error',
  BADREQUEST = 'BAD_REQUEST',
  NOTDEFINED = 'NOT_DEFINED',
}

/**
 *
 * @export
 * @interface CardInfoResponse
 */
export interface CardInfoResponse {
  /**
   *
   * @type {number}
   * @memberof CardInfoResponse
   */
  isBinDefined?: number
  /**
   *
   * @type {string}
   * @memberof CardInfoResponse
   */
  paymentSystem?: string
  /**
   *
   * @type {string}
   * @memberof CardInfoResponse
   */
  country?: string
  /**
   *
   * @type {string}
   * @memberof CardInfoResponse
   */
  netSystem?: string
  /**
   *
   * @type {string}
   * @memberof CardInfoResponse
   */
  memberName?: string
  /**
   *
   * @type {string}
   * @memberof CardInfoResponse
   */
  bankNameEn?: string
  /**
   *
   * @type {string}
   * @memberof CardInfoResponse
   */
  bankNameRus?: string
  /**
   *
   * @type {string}
   * @memberof CardInfoResponse
   */
  logoReference?: string
  /**
   *
   * @type {number}
   * @memberof CardInfoResponse
   */
  isLuhnAlgorithm?: number
  /**
   *
   * @type {CardInfoRequestStatus}
   * @memberof CardInfoResponse
   */
  status?: CardInfoRequestStatus
}
/**
 *
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
  /**
   *
   * @type {string}
   * @memberof ErrorResponse
   */
  requestId?: string
  /**
   *
   * @type {Error}
   * @memberof ErrorResponse
   */
  error?: Error
}
/**
 *
 * @export
 * @interface ModelError
 */
export interface ModelError {
  /**
   *
   * @type {string}
   * @memberof ModelError
   */
  error?: string
  /**
   *
   * @type {string}
   * @memberof ModelError
   */
  error_description?: string
  /**
   *
   * @type {string}
   * @memberof ModelError
   */
  error_id?: string
}

/**
 * BinCardInfoApi - axios parameter creator
 * @export
 */
export const BinCardInfoApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Сервис определения Банка.
     * @param {CardInfoRequest} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCardInfo(body: CardInfoRequest, options: any = {}): RequestArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling getCardInfo.')
      }
      const localVarPath = `/check-cards/getCardInfo`
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      const needsSerialization =
        typeof body !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json'
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : body || ''

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * BinCardInfoApi - functional programming interface
 * @export
 */
export const BinCardInfoApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Сервис определения Банка.
     * @param {CardInfoRequest} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCardInfo(
      body: CardInfoRequest,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CardInfoResponse> {
      const localVarAxiosArgs = BinCardInfoApiAxiosParamCreator(configuration).getCardInfo(body, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * BinCardInfoApi - factory interface
 * @export
 */
export const BinCardInfoApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Сервис определения Банка.
     * @param {CardInfoRequest} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCardInfo(body: CardInfoRequest, options?: any): AxiosPromise<CardInfoResponse> {
      return BinCardInfoApiFp(configuration).getCardInfo(body, options)(axios, basePath)
    },
  }
}

/**
 * BinCardInfoApi - object-oriented interface
 * @export
 * @class BinCardInfoApi
 * @extends {BaseAPI}
 */
export class BinCardInfoApi extends BaseAPI {
  /**
   *
   * @summary Сервис определения Банка.
   * @param {CardInfoRequest} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BinCardInfoApi
   */
  public getCardInfo(body: CardInfoRequest, options?: any) {
    return BinCardInfoApiFp(this.configuration).getCardInfo(body, options)(this.axios, this.basePath)
  }
}
