import { DependencyList, EffectCallback, useCallback, useEffect, useRef } from 'react'
import debounce from 'lodash.debounce'

export function useLazyEffect(effect: EffectCallback, deps: DependencyList = [], wait = 150) {
  const cleanUp = useRef<void | (() => void)>()
  const effectRef = useRef<EffectCallback>()

  effectRef.current = useCallback(effect, [deps, effect])
  const lazyEffect = useCallback(() => {
    debounce(() => {
      cleanUp.current = effectRef.current?.()
    }, wait)()
  }, [wait])

  useEffect(() => lazyEffect(), [deps, lazyEffect])
  useEffect(() => () => cleanUp.current instanceof Function ? cleanUp.current() : undefined, [])
}
