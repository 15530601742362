import FormUtils from '@components/PaymentMethodSelector/paymentDetector/CardPayment/FormUtils'

export interface AcsRequest {
  mdOrder: string
  paReq: string
  callbackUrl: string
  url: string
  threeDsMethodUrl?: string
  threeDsMethodData?: string
  threeDsServerTransId?: string
}

class ThreeDsAuth {
  static call(acsRequest: AcsRequest): Promise<boolean> {
    const formElement = FormUtils.createForm(acsRequest.url)

    formElement.appendChild(FormUtils.createField('TermUrl', acsRequest.callbackUrl))
    formElement.appendChild(FormUtils.createField('PaReq', acsRequest.paReq))
    formElement.appendChild(FormUtils.createField('MD', acsRequest?.mdOrder))

    document.body.append(formElement)
    formElement.submit()

    return Promise.resolve(true)
  }
}

export { ThreeDsAuth }
