import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FormikValues } from 'formik';
import { isEqual, noop } from 'lodash';
import { useRouter } from 'next/router';
import { Divider, Flex, theme, useBreakpoint, useToast } from '@mtsbank/ui-kit';
import { PageContainer } from '@components/PageContainer/PageContainer';
import { Container } from '@components/Container/Container';
import { authHelper } from '@root/utils/authHelper/AuthHelper';
import { catalogManagerApi } from '@api/ump/catalog-manager';
import { Payee } from '@open-api/ump/catalog-manager';
import { uuid } from '@root/utils/generateUuid';
import { AUTH_PATH, CATEGORY_PATH } from '@root/constants';
import { route } from '@root/utils/route/route';
import { showPayeePageGtm } from '@root/utils/gtm/tsp/events';
import { SberPayContext } from '@root/pages/perevod_v_sber';
import { BlockHeader } from './BlockHeader';
import { Switch } from './Switch';
import { FormikHandlerLoginForm } from './personalAccount/FormikHandlerLoginForm';
import { FormikHandlerWithoutAuth } from './withoutAuthorization/FormikHandlerWithoutAuth';
import { getPcekZone } from '../CardToCard/utils/helpers';
import { OTP } from './OTP';
import { ContentWrapper, FormWrapper, OtpWrapper, Wrapper } from './styled';
import { TabData, TabState } from './Switch/type';
import { Loading } from '../Loading/Loading';
import { Content } from '../Content/Content';
import { MTS_UNION_FIELD_NAME, phoneService } from './constants';
import { isShowBannerGKMts, isSNGRequiredAuthCheck } from './utils/helpers';
import { MtsGCBanner } from './MtsGCBanner/MtsGCBanner';
import { PromotionBanner } from './PromotionBanner/PromotionBanner';
const tabsAuthContent = {
  [TabState.LEFT]: FormikHandlerWithoutAuth,
  [TabState.RIGHT]: FormikHandlerLoginForm
};
type PaymentFormDataContextType = {
  updateValues: (values: FormikValues) => void;
  values: FormikValues;
};
const authTabs: TabData[] = [{
  directtion: TabState.LEFT,
  text: 'Без авторизации'
}, {
  directtion: TabState.RIGHT,
  text: 'Личный кабинет'
}];
const linkedTabs: TabData[] = [{
  directtion: TabState.LEFT,
  text: 'Проверить'
}, {
  directtion: TabState.RIGHT,
  text: 'Оплатить'
}];
export const PaymentFormDataContext = React.createContext<PaymentFormDataContextType>({
  updateValues: (values: FormikValues) => noop(values),
  values: ({} as FormikValues)
});
interface TspPayeeProps {
  tspId?: string;
  isMM?: boolean;
}
export const TspPayee: FC<TspPayeeProps> = ({
  tspId,
  isMM
}) => {
  const isAuthenticated = isMM ? true : authHelper.isAuthenticated();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [payeeData, setPayeeData] = useState<Payee>(null);
  const [authTab, setAuthTab] = useState<TabState>(TabState.LEFT);
  const [linkedTSPTab, setLinkedTSPTab] = useState<TabState>(TabState.RIGHT);
  const [mdOrder, setMdOrder] = useState(null);
  const [isOtp, setIsOtp] = useState(false);
  const [formsContextData, setFormsContextData] = useState({});
  const [linkedPayeeData, setLinkedPayeeData] = useState<Payee>(null);
  const router = useRouter();
  const id = ((router?.query?.id || tspId) as string);
  const isWebView = (router?.query?.isWebView as string);
  const hasWebView = isWebView === 'true';
  const {
    isMobile
  } = useBreakpoint();
  const {
    toast
  } = useToast();
  const sberPayContext = useContext(SberPayContext);
  const isInvoicesAuthRequired = !isAuthenticated && payeeData?.searchInvoicesEnabled;
  const isSNGAuthRequired = !isAuthenticated && (payeeData?.categoryName === 'uslugiSNG1' || payeeData?.categoryName === 'uslugiSNG') && isSNGRequiredAuthCheck(payeeData?.serviceId);
  const ActiveAuthTab = tabsAuthContent[authTab] ? tabsAuthContent[authTab] : null;
  const icon = payeeData?.icon ? `${process.env.NEXT_PUBLIC_STATIC_HOST}/prov-icons/${payeeData?.icon}` : null;
  const currentLinkedTabData = {
    [TabState.LEFT]: linkedPayeeData,
    [TabState.RIGHT]: payeeData
  };
  const zone = getPcekZone(isMM);
  const isLinkedTSPSwithShow = isAuthenticated && Boolean(linkedPayeeData);
  useEffect(() => {
    if (id && id !== 'phone') {
      fetchPayeeData(id).then((payeeData: Payee) => {
        setPayeeData(payeeData);
      }).catch(error => toast('error', 'Ошибка получения данных', (error.errorMessage as string) || '', {
        withClose: true,
        withTimeout: true,
        timeout: 3000
      }));
    }
    if (id === 'phone') {
      setPayeeData(phoneService);
    }
  }, [id]);
  useEffect(() => {
    if (payeeData) {
      showPayeePageGtm(payeeData.serviceId, payeeData.providerName);
    }
  }, [payeeData]);
  const fetchPayeeData = useCallback((id: string) => new Promise((res, rej) => {
    setIsDataLoading(true);
    catalogManagerApi.getPayees(zone, uuid(), id, '', true, true, zone === 'anonymous' ? {
      headers: {
        'client-Id': 'mts-money-web-mtsid'
      }
    } : sberPayContext?.headers || {}).then(result => {
      res(result.data.payees[0]);
    }).catch(error => rej(error));
  }), [zone, isMM]);
  const redirectToSSO = () => {
    localStorage.setItem('sso_returnUrl', window.location.href);
    window.location.href = `${AUTH_PATH}`;
  };
  const fetchLinkedPayee = useCallback(() => {
    const linkedId = isAuthenticated && payeeData?.params?.find(param => param.name === 'settings_linkedId')?.defaultValue;
    if (linkedId) {
      fetchPayeeData(linkedId).then((payeeData: Payee) => {
        setLinkedPayeeData(payeeData);
        setLinkedTSPTab(TabState.LEFT);
      }).catch(error => toast('error', 'Ошибка получения данных', (error.errorMessage as string) || '', {
        withClose: true,
        withTimeout: true,
        timeout: 3000
      })).finally(() => setIsDataLoading(false));
    } else {
      setIsDataLoading(false);
    }
  }, [fetchPayeeData, payeeData]);
  useEffect(() => {
    if (payeeData) {
      if (isInvoicesAuthRequired || isSNGAuthRequired) {
        redirectToSSO();
      } else {
        fetchLinkedPayee();
      }
    }
  }, [fetchLinkedPayee, isAuthenticated, payeeData]);
  const paymentFormDataContextValues = useMemo<PaymentFormDataContextType>(() => ({
    values: formsContextData[authTab] || {},
    updateValues: (values: FormikValues) => {
      if (!isEqual(formsContextData[authTab], values)) {
        setFormsContextData({
          ...formsContextData,
          [authTab]: values
        });
      }
    }
  }), [authTab, formsContextData, setFormsContextData]);
  const hadleBackButtonClick = useCallback(() => {
    const path = hasWebView ? `${route.generatePath(CATEGORY_PATH, {
      id: payeeData.categoryName === 'internet_and_tv_gk' ? 'internet_and_tv' : payeeData.categoryName
    })}?isWebView=true` : route.generatePath(CATEGORY_PATH, {
      id: payeeData.categoryName === 'internet_and_tv_gk' ? 'internet_and_tv' : payeeData.categoryName
    });
    router.push(path);
  }, [hasWebView, payeeData?.categoryName, router]);
  return <PageContainer isGoBack isHideArrow={isMM} onClick={hadleBackButtonClick} titleBack="Назад" background={isMobile ? theme.colors.white : theme.colors.neutral.g700}>
      <Container innerContentFluid background={theme.colors.neutral.g700}>
        <Wrapper>
          {isDataLoading && <Content.Padding top={52}>
              <Flex justify="center" alignItems="center">
                <Loading />
              </Flex>
            </Content.Padding>}
          {payeeData && !isDataLoading && <>
              <BlockHeader text={payeeData.subline} icon={icon} heading={payeeData.serviceId !== '19171' ? payeeData.providerName : MTS_UNION_FIELD_NAME} />
              <Divider />
              <ContentWrapper>
                {isOtp ? <OtpWrapper>
                    <OTP mdOrder={mdOrder} />
                  </OtpWrapper> : <FormWrapper>
                    <PromotionBanner />

                    <PaymentFormDataContext.Provider value={paymentFormDataContextValues}>
                      {!isAuthenticated && <Switch activeTab={authTab} handleClick={setAuthTab} tabsList={authTabs} />}
                      {isLinkedTSPSwithShow && isAuthenticated && <Switch activeTab={linkedTSPTab} handleClick={setLinkedTSPTab} tabsList={linkedTabs} />}
                      {isShowBannerGKMts(payeeData?.serviceId) && <MtsGCBanner serviceId={payeeData?.serviceId} title={payeeData?.providerName} />}
                      <ActiveAuthTab setPayeeData={setPayeeData} setMdOrder={setMdOrder} setIsOtp={setIsOtp} serviceId={payeeData.serviceId} serviceInfo={currentLinkedTabData[linkedTSPTab]} name={payeeData.providerName} />
                    </PaymentFormDataContext.Provider>
                  </FormWrapper>}
              </ContentWrapper>
            </>}
        </Wrapper>
      </Container>
    </PageContainer>;
};