import React from 'react';
import { Heading, Text, SkeletonCir } from '@mtsbank/ui-kit';
import { Wrapper, HeaderWrapper, HeadingWrapper, Logo, LogoWrapper } from './styled';
export const BlockHeader = ({
  heading,
  text,
  icon
}: {
  heading: string;
  text?: string;
  icon?: string;
}) => <Wrapper>
    <HeaderWrapper>
      <LogoWrapper>{icon ? <Logo src={icon} /> : <SkeletonCir height={64} />}</LogoWrapper>
      <div>
        <HeadingWrapper>
          <Heading h={3}>{heading}</Heading>
        </HeadingWrapper>
        {text && <Text fontWeight="medium">{text}</Text>}
      </div>
    </HeaderWrapper>
  </Wrapper>;