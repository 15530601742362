import React from 'react'

import { ThreeDsAuth } from '@components/PaymentMethodSelector/paymentDetector/CardPayment/ThreeDsAuth'
import { TransferResponse } from '@open-api/ump/ewallet'
import { Notice } from '@components/Notice/Notice'
import { NoticeType } from '@components/Notice/types'
import { SberPayContextProps } from '@root/pages/perevod_v_sber'
import { ConfirmationType } from '../types'
import { ThreeDs2PcekAuth } from '../threeDs2PcekAuth/ThreeDs2PcekAuth'

type Props = {
  data: TransferResponse
  sberPayContext?: SberPayContextProps
  getResultPath: (mdOrder: string) => string
  handleCallOtp?: (mdOrder: string) => void
  handleCall3DS?: () => void
  handleCall3DS2?: (data: TransferResponse) => void
  handleError?: (error?: string) => void
}

export const getConfirmationContent = ({
  data,
  sberPayContext,
  getResultPath,
  handleCallOtp,
  handleCall3DS,
  handleCall3DS2,
  handleError,
}: Props) => {
  switch (data.confirmationType) {
    case ConfirmationType.OTP_PAYMENT_PAGE:
    case ConfirmationType.OTP_SMS_CALLBACK:
      handleCallOtp?.(data.mdOrder)
      break

    case ConfirmationType.FINISH_3DS:
      if (data?.mdOrder && data?.acsUrl && data?.PaReq) {
        handleCall3DS?.()

        ThreeDsAuth.call({
          mdOrder: data.mdOrder,
          url: data.acsUrl,
          paReq: data.PaReq,
          callbackUrl: getResultPath(data.mdOrder),
        })
      }
      break

    case ConfirmationType.FINISH_3DS2:
      if (data?.mdOrder) {
        ThreeDs2PcekAuth.call(
          {
            mdOrder: data.mdOrder,
            url: data?.acsUrl,
            callbackUrl: getResultPath(data.mdOrder),
            threeDsMethodData: data?.threeDsMethodData,
            threeDsMethodUrl: data?.threeDsMethodUrl,
          },
          sberPayContext
        )
          .then((data) => {
            // frictionless flow
            if (!data?.errorCode) {
              handleCall3DS2(data)
            } else {
              handleError(data?.errorMessage)
            }
          })
          .catch(() => {
            handleError()
          })
      }
      break

    default:
      return <Notice type={NoticeType.WARNING} message="Повторите попытку" />
  }
}
