// tslint:disable
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url'
import { Configuration } from './configuration'
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base'

/**
 *
 * @export
 * @interface GetInfoMyMsisdnRs
 */
export interface GetInfoMyMsisdnRs {
  /**
   *
   * @type {number}
   * @memberof GetInfoMyMsisdnRs
   */
  errorCode: number
  /**
   *
   * @type {string}
   * @memberof GetInfoMyMsisdnRs
   */
  errorCause?: string
  /**
   *
   * @type {string}
   * @memberof GetInfoMyMsisdnRs
   */
  operatorCode?: string
  /**
   *
   * @type {string}
   * @memberof GetInfoMyMsisdnRs
   */
  regionCode?: string
  /**
   *
   * @type {number}
   * @memberof GetInfoMyMsisdnRs
   */
  MNC?: number
  /**
   *
   * @type {string}
   * @memberof GetInfoMyMsisdnRs
   */
  route?: string
  /**
   *
   * @type {string}
   * @memberof GetInfoMyMsisdnRs
   */
  topUpServiceUniqueId?: string
}

/**
 * ControllerApi - axios parameter creator
 * @export
 */
export const ControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Получить информацию об операторе сотовой связи по переданному номеру телефона
     * @summary Запрос оператора
     * @param {'b' | 'anonymous'} zone
     * @param {string} msisdn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInfoMyMsisdnDo(zone: 'b' | 'anonymous', msisdn: string, options: any = {}): RequestArgs {
      // verify required parameter 'zone' is not null or undefined
      if (zone === null || zone === undefined) {
        throw new RequiredError('zone', 'Required parameter zone was null or undefined when calling getInfoMyMsisdnDo.')
      }
      // verify required parameter 'msisdn' is not null or undefined
      if (msisdn === null || msisdn === undefined) {
        throw new RequiredError(
          'msisdn',
          'Required parameter msisdn was null or undefined when calling getInfoMyMsisdnDo.'
        )
      }
      const localVarPath = `/{zone}/ewcat/queries/getInfoMyMsisdn`.replace(
        `{${'zone'}}`,
        encodeURIComponent(String(zone))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (msisdn !== undefined) {
        localVarQueryParameter['msisdn'] = msisdn
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ControllerApi - functional programming interface
 * @export
 */
export const ControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Получить информацию об операторе сотовой связи по переданному номеру телефона
     * @summary Запрос оператора
     * @param {'b' | 'anonymous'} zone
     * @param {string} msisdn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInfoMyMsisdnDo(
      zone: 'b' | 'anonymous',
      msisdn: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetInfoMyMsisdnRs> {
      const localVarAxiosArgs = ControllerApiAxiosParamCreator(configuration).getInfoMyMsisdnDo(zone, msisdn, options)
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * ControllerApi - factory interface
 * @export
 */
export const ControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     * Получить информацию об операторе сотовой связи по переданному номеру телефона
     * @summary Запрос оператора
     * @param {'b' | 'anonymous'} zone
     * @param {string} msisdn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInfoMyMsisdnDo(zone: 'b' | 'anonymous', msisdn: string, options?: any): AxiosPromise<GetInfoMyMsisdnRs> {
      return ControllerApiFp(configuration).getInfoMyMsisdnDo(zone, msisdn, options)(axios, basePath)
    },
  }
}

/**
 * ControllerApi - object-oriented interface
 * @export
 * @class ControllerApi
 * @extends {BaseAPI}
 */
export class ControllerApi extends BaseAPI {
  /**
   * Получить информацию об операторе сотовой связи по переданному номеру телефона
   * @summary Запрос оператора
   * @param {'b' | 'anonymous'} zone
   * @param {string} msisdn
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ControllerApi
   */
  public getInfoMyMsisdnDo(zone: 'b' | 'anonymous', msisdn: string, options?: any) {
    return ControllerApiFp(this.configuration).getInfoMyMsisdnDo(zone, msisdn, options)(this.axios, this.basePath)
  }
}
