import React, { FC } from 'react'
import { Field, useFormikContext } from 'formik'
import { Button, theme } from '@mtsbank/ui-kit'
import { FieldsProps } from '@components/TspPayee/withoutAuthorization/type'
import { Content } from '@components/Content/Content'

export const SearchInvoicesFields: FC<FieldsProps> = ({ fields = [], isSearchInvoices, serviceInfo, isShowButton }) => {
  const { submitForm } = useFormikContext()

  return (
    <Content.Margin mobTop={32} top={40}>
      {fields.map((field) => (
        <Content marginBottom={theme.spacings.xs} key={`field-wrapper_${field.name}_${serviceInfo?.serviceId}`}>
          <Field {...field} size="lg" />
        </Content>
      ))}

      {isShowButton && (
        <Button onClick={submitForm} isLoading={isSearchInvoices} size="lg">
          Найти счета
        </Button>
      )}
    </Content.Margin>
  )
}
