import React, { FC, createContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Notice } from '@components/Notice/Notice'
import { AUTH_PATH } from '@root/constants'
import { authHelper } from '@utils/authHelper/AuthHelper'
import { setIsWebView } from '@reducers/settings/settings'
import { selectSettingsState } from '@selectors/settings'
import TspPayee from '../pay/[id]'

export const getServerSideProps = ({ query }) => {
  const token_mts = query?.token_id || null

  return {
    props: {
      token_mts,
    },
  }
}

export const SBER_TSP_ID = '22122'
export const X_MTS_ID_HEADER = (token_mts: string) => ({
  headers: { 'client-id': 'mts-money-web-mtsid', 'X-MTS-ID': true, Authorization: `Bearer ${token_mts}` },
})

interface SberPayProps {
  token_mts?: string
}

export interface SberPayContextProps {
  isSberPay: boolean
  token_mts?: string | null
  headers?: object
  isAuthMTS?: boolean
}

const initialState = {
  token_mts: null,
  headers: {},
  isSberPay: true,
  isAuthMTS: false,
}

export const SberPayContext = createContext<SberPayContextProps>(initialState)

const SberPay: FC<SberPayProps> = ({ token_mts }) => {
  const { isAuthenticated } = authHelper
  const isAuthSSO = isAuthenticated()

  const context = initialState

  const dispatch = useDispatch()
  const { isWebView } = useSelector(selectSettingsState)

  useEffect(() => {
    dispatch(setIsWebView(true))

    return () => {
      dispatch(setIsWebView(!isWebView))
    }
  }, [dispatch, isWebView])

  if (!token_mts && !isAuthSSO) {
    localStorage.setItem('sso_returnUrl', window.location.href)
    window.location.href = `${AUTH_PATH}`

    return <div />
  }

  if (token_mts) {
    context.isAuthMTS = true // Токен на exp будет проверен МП
    context.headers = X_MTS_ID_HEADER(token_mts)
    context.token_mts = token_mts

    window.localStorage.setItem('access_token', token_mts)
  }

  return (
    <SberPayContext.Provider value={context}>
      <TspPayee isMM={context.isSberPay} tspId={SBER_TSP_ID} />
    </SberPayContext.Provider>
  )
}

export default SberPay
