import { CardType } from '@root/types/bindings'

export const cardIconMapping = {
  [CardType.UNKNOWN]: 'icon-24/Card',
  [CardType.VISA]: 'payment/visa',
  [CardType.MIR]: 'payment/mir',
  [CardType.MASTERCARD]: 'payment/masterCard',
  [CardType.MAESTRO]: 'payment/maestro',
  [CardType.UNIONPAY]: 'payment/unionPay',
  [CardType.VIRTUAL]: 'icon-24/Card',
}
