import React, { FC } from 'react';
import { Icon, Text } from '@mtsbank/ui-kit';
import { MtsGCBannerBox, TextWrapper, Link } from './styled';
import { bannerIdentificatorsConfig } from '../config/bannerInfo';
interface Props {
  title: string;
  serviceId: string;
}
export const MtsGCBanner: FC<Props> = ({
  title,
  serviceId
}) => {
  const queryString = window.location.search;
  const searchParams = new URLSearchParams(queryString);
  const isWebView = searchParams.get('isWebView');
  const hasWebView = isWebView === 'true';
  return <MtsGCBannerBox>
      <TextWrapper>
        <div>
          <Icon icon="icon-24/SuperInfo" />
        </div>
        <Text>
          Для оплаты {title} по{'\u00A0'}
          {bannerIdentificatorsConfig[serviceId]} Вам доступна кнопка{' '}
          <Link href={hasWebView ? '/spa/pay/19171?isWebView=true' : '/spa/pay/19171'}>
            «Интернет, ТВ{'\u00A0'}и{'\u00A0'}Телефония (МТС и{'\u00A0'}МГТС)»
          </Link>
          .
          <br />
          <br />
          Оплата по{'\u00A0'}кнопке «{title}» доступна до{'\u00A0'}31.07.2024{'\u00A0'}г.
        </Text>
      </TextWrapper>
    </MtsGCBannerBox>;
};