import {
  actionGroups,
  eventActions,
  eventCategories,
  eventContents,
  eventLabels,
  events,
  EventType,
  screenNames,
  sendGmtEvent,
} from '@utils/gtm/baseFunctions'
import {
  BannerEventsType,
  FieldEventLabelType,
  LinkValuesTypes,
  PaymentResultButtonsType,
  SwitchPhoneOrderNumberType,
  TabPaymentsButtonsType,
  FieldTooltipEventContextType,
  filterName,
  FieldValueChangedType,
} from '@utils/gtm/serviceIdPayment/eventParams'
import { translit } from '@utils/gtm/translit'

export const mobilePaymentBaseEventGtm = (
  event: EventType,
  serviceId: string,
  serviceName: string,
  eventAction: string,
  eventLabel: string
) =>
  sendGmtEvent({
    ...event,
    event: events.mtsEvent,
    eventCategory: eventCategories.platezhi,
    eventAction,
    eventLabel,
    screenName: getPaymentScreenName(serviceId),
    pageType: getPaymentScreenName(serviceId),
    actionGroup: actionGroups.interactions,
    productId: serviceId,
    productName: translit(serviceName).toLowerCase(),
  })

export const formShowGtm = (event: EventType, serviceId: string, serviceName: string) =>
  sendGmtEvent({
    ...event,
    event: events.mtsEvent,
    eventCategory: eventCategories.platezhi,
    eventAction: eventActions.formShow,
    eventLabel: eventLabels.popolnenieScheta,
    screenName: getPaymentScreenName(serviceId),
    pageType: getPaymentScreenName(serviceId),
    actionGroup: actionGroups.interactions,
    eventContent: localStorage.getItem('access_token') ? eventContents.vLichnomKabinete : eventContents.bezAvtorizacii,
    productId: serviceId,
    productName: translit(serviceName).toLowerCase(),
  })

//
export const correctPhoneOnAuthTabGtm = (event: EventType, serviceId: string, serviceName: string) =>
  sendGmtEvent({
    ...event,
    event: events.mtsEvent,
    eventCategory: eventCategories.platezhi,
    eventAction: eventActions.confirmed,
    eventLabel: eventLabels.nomerTelefona,
    screenName: getPaymentScreenName(serviceId),
    pageType: getPaymentScreenName(serviceId),
    actionGroup: actionGroups.interactions,
    eventContent: eventContents.vLichnomKabinete,
    productId: serviceId,
    productName: translit(serviceName).toLowerCase(),
  })

export const submitOnAuthTabGtm = (event: EventType, serviceId: string, serviceName: string) =>
  sendGmtEvent({
    ...event,
    event: events.mtsEvent,
    eventCategory: eventCategories.platezhi,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.dalee,
    screenName: getPaymentScreenName(serviceId),
    pageType: getPaymentScreenName(serviceId),
    actionGroup: actionGroups.interactions,
    eventContent: eventContents.vLichnomKabinete,
    productId: serviceId,
    productName: translit(serviceName).toLowerCase(),
  })

export const tabChangeGtm = (
  event: EventType,
  serviceId: string,
  serviceName: string,
  button: TabPaymentsButtonsType,
  eventContent: TabPaymentsButtonsType
) =>
  sendGmtEvent({
    ...event,
    event: events.mtsEvent,
    eventCategory: eventCategories.platezhi,
    eventAction: eventActions.elementClick,
    eventLabel: button,
    screenName: getPaymentScreenName(serviceId),
    pageType: getPaymentScreenName(serviceId),
    actionGroup: actionGroups.interactions,
    eventContent,
    productId: serviceId,
    productName: translit(serviceName).toLowerCase(),
  })

export const switchPhoneOrderNumberGtm = (
  event: EventType,
  serviceId: string,
  serviceName: string,
  switchType: SwitchPhoneOrderNumberType
) =>
  sendGmtEvent({
    ...event,
    event: events.mtsEvent,
    eventCategory: eventCategories.platezhi,
    eventAction: eventActions.elementClick,
    eventLabel: switchType,
    screenName: getPaymentScreenName(serviceId),
    pageType: getPaymentScreenName(serviceId),
    actionGroup: actionGroups.interactions,
    eventContent: localStorage.getItem('access_token') ? eventContents.vLichnomKabinete : eventContents.bezAvtorizacii,
    productId: serviceId,
    productName: translit(serviceName).toLowerCase(),
  })

export const fieldSuccessFilledGtm = (
  event: EventType,
  serviceId: string,
  serviceName: string,
  eventLabel: FieldEventLabelType,
  context: FieldValueChangedType,
  eventValue: string
) =>
  sendGmtEvent({
    ...event,
    event: events.mtsEvent,
    eventCategory: eventCategories.platezhi,
    eventAction: eventActions.confirmed,
    eventLabel,
    eventContent: localStorage.getItem('access_token') ? eventContents.vLichnomKabinete : eventContents.bezAvtorizacii,
    screenName: getPaymentScreenName(serviceId),
    pageType: getPaymentScreenName(serviceId),
    actionGroup: actionGroups.interactions,
    eventContext: context,
    productId: serviceId,
    productName: translit(serviceName).toLowerCase(),
    filterName: filterName.rekvizity,
    eventValue,
  })

export const showFieldErrorGtm = (
  event: EventType,
  eventContext: string,
  serviceId: string,
  serviceName: string,
  errorText: string
) =>
  sendGmtEvent({
    ...event,
    event: events.mtsEvent,
    eventCategory: eventCategories.platezhi,
    eventAction: eventActions.errorShow,
    eventLabel: translit(errorText),
    eventContext,
    screenName: getPaymentScreenName(serviceId),
    pageType: getPaymentScreenName(serviceId),
    actionGroup: actionGroups.interactions,
    eventContent: localStorage.getItem('access_token') ? eventContents.vLichnomKabinete : eventContents.bezAvtorizacii,
    productId: serviceId,
    productName: translit(serviceName).toLowerCase(),
  })

// Выбор / переключение способа оплаты
export const selectPaymentMethodGtm = (event: EventType, serviceId: string, serviceName: string) =>
  sendGmtEvent({
    event: events.mtsEvent,
    eventCategory: eventCategories.platezhi,
    eventAction: eventActions.confirmed,
    eventLabel: eventLabels.sposobOplaty,
    screenName: getPaymentScreenName(serviceId),
    pageType: getPaymentScreenName(serviceId),
    actionGroup: actionGroups.interactions,
    eventContent: localStorage.getItem('access_token') ? eventContents.vLichnomKabinete : eventContents.bezAvtorizacii,
    productId: serviceId,
    productName: translit(serviceName).toLowerCase(),
  })

export const linkClickSendStatisticsGtm = (
  event: EventType,
  serviceId: string,
  serviceName: string,
  link: LinkValuesTypes
) =>
  sendGmtEvent({
    ...event,
    event: events.mtsEvent,
    eventCategory: eventCategories.platezhi,
    eventAction: eventActions.linkClick,
    eventLabel: link,
    screenName: getPaymentScreenName(serviceId),
    pageType: getPaymentScreenName(serviceId),
    actionGroup: actionGroups.interactions,
    eventContent: localStorage.getItem('access_token') ? eventContents.vLichnomKabinete : eventContents.bezAvtorizacii,
    productId: serviceId,
    productName: translit(serviceName).toLowerCase(),
  })

export const doPaymentGtm = (event: EventType, serviceId: string, serviceName: string, eventContext: string) =>
  sendGmtEvent({
    ...event,
    eventContext,
    event: events.mtsEvent,
    eventCategory: eventCategories.platezhi,
    eventAction: eventActions.buttonClick,
    eventLabel: eventLabels.oplatit,
    screenName: getPaymentScreenName(serviceId),
    pageType: getPaymentScreenName(serviceId),
    actionGroup: actionGroups.interactions,
    eventContent: localStorage.getItem('access_token') ? eventContents.vLichnomKabinete : eventContents.bezAvtorizacii,
    productId: serviceId,
    productName: translit(serviceName).toLowerCase(),
  })

export const paymentResultGtm = (
  event: EventType,
  success: boolean,
  serviceId: string,
  serviceName: string,
  eventContext
) =>
  sendGmtEvent({
    ...event,
    eventContext,
    event: events.mtsEvent,
    eventCategory: eventCategories.platezhi,
    eventAction: success ? eventActions.confirmed : eventActions.rejected,
    eventLabel: eventLabels.oplata,
    screenName: screenNames.payResult,
    pageType: screenNames.payResult,
    actionGroup: actionGroups.interactions,
    productId: serviceId,
    productName: translit(serviceName).toLowerCase(),
  })

export const checkButtonClickGtm = (
  event: EventType,
  type: PaymentResultButtonsType,
  serviceId: string,
  serviceName: string
) =>
  sendGmtEvent({
    ...event,
    event: events.mtsChek,
    eventCategory: eventCategories.chek,
    eventAction: eventActions.elementClick,
    eventLabel: type,
    screenName: screenNames.payResult,
    pageType: screenNames.payResult,
    actionGroup: actionGroups.interactions,
    productId: serviceId,
    productName: translit(serviceName).toLowerCase(),
  })

export const bannerEventGtm = (
  event: EventType,
  bannerEvent: BannerEventsType,
  label: string,
  serviceId,
  serviceName: string
) =>
  sendGmtEvent({
    ...event,
    event: events.mtsAdv,
    eventCategory: eventCategories.advertising,
    eventAction: bannerEvent,
    eventLabel: label,
    screenName: screenNames.payResult,
    pageType: screenNames.payResult,
    actionGroup: actionGroups.interactions,
    productId: serviceId,
    productName: translit(serviceName).toLowerCase(),
  })

export const showClickTooltipGtm = (
  event: EventType,
  serviceId: string,
  serviceName: string,
  eventContext: FieldTooltipEventContextType
) =>
  sendGmtEvent({
    ...event,
    eventContext,
    screenName: getPaymentScreenName(serviceId),
    pageType: getPaymentScreenName(serviceId),

    event: events.mtsEvent,
    eventCategory: eventCategories.platezhi,
    eventAction: eventActions.elementShow,
    eventLabel: eventLabels.podskazka,
    eventContent: localStorage.getItem('access_token') ? eventContents.vLichnomKabinete : eventContents.bezAvtorizacii,
    filterName: filterName.rekvizity,

    actionGroup: actionGroups.interactions,
    productId: serviceId,
    productName: translit(serviceName).toLowerCase(),
  })

const getPaymentScreenName = (serviceId: string) =>
  serviceId ? screenNames.payServiceId(serviceId) : screenNames.payPhone
