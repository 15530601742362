import React from 'react';
import { Button, Wrapper } from './styled';
import { TabData, TabState } from './type';
export const Switch = ({
  handleClick,
  activeTab,
  tabsList
}: {
  handleClick: (tab: TabState) => void;
  activeTab: TabState;
  tabsList: TabData[];
}) => {
  const handleTabClick = (tab: TabState) => {
    handleClick(tab);
  };
  return <Wrapper>
      {tabsList.map(tabData => <Button key={tabData.directtion} active={activeTab === tabData.directtion} onClick={() => handleTabClick(tabData.directtion)}>
          {tabData.text}
        </Button>)}
    </Wrapper>;
};