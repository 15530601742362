import React, { FC, useCallback, useEffect, useState } from 'react';
import { Accordion, Button, Icon, Modal, SkeletonRect, Text, theme, useBreakpoint, useDidMount, useToast } from '@mtsbank/ui-kit';
import { Cell } from '@mtsbank/common';
import { Param } from '@open-api/ump/catalog-manager';
import { EWCharge, FinePhoto } from '@open-api/ump/charge-invoice';
import { chargeApi, fileManagerApi } from '@root/api/ump/charge-invoice';
import { uuid } from '@root/utils/generateUuid';
import { Content } from '@root/components/Content/Content';
import { authHelper } from '@root/utils/authHelper/AuthHelper';
import { AccordionGridWrapper, AccordionTitle, ButtonsWrapper, CustomMoney, Flexwrapper, FullPhoto, FullPhotoWrpper, ModalPayText, MoneyWrapper, PaymentButtonWrapper, Photo, PhotosWrapper, PreviewWrapper, StyledModal } from './styled';
interface Props {
  paymentParams: Param[];
  charge: EWCharge;
  setSelectedCharge: (charge: EWCharge) => void;
  updateChargesResult: () => void;
}
export const Charge: FC<Props> = ({
  paymentParams,
  charge,
  setSelectedCharge,
  updateChargesResult
}) => {
  const [isFetchingPhotos, setIsFetchingPhotos] = useState(false);
  const [photos, setPhotos] = useState<FinePhoto[]>([]);
  const [activePhoto, setActivePhoto] = useState<FinePhoto>(null);
  const [isPhotosModalOpen, setIsOpenPhotosModal] = useState<boolean>(false);
  const [isPayChargeModalOpen, setIsPayChargeModal] = useState<boolean>(false);
  const [isPayChargeLoading, setIsPayChargeLoading] = useState<boolean>(false);
  const {
    toast
  } = useToast();
  const {
    userProfile
  } = authHelper;
  const {
    isTablet
  } = useBreakpoint();
  const invoiceIdParam = paymentParams.find(item => item.name === 'invoiceId');
  const {
    title
  } = invoiceIdParam;
  const {
    paymentDetails
  } = charge;
  const {
    invoiceId,
    chargeType,
    amount,
    amountWithDiscount,
    discountSizePercent,
    discountDate
  } = (paymentDetails as any);
  useDidMount(() => {
    if (chargeType === 'FINES') {
      setIsFetchingPhotos(true);
      fileManagerApi.getFinesPhotosByUINUsingPOST('mtsmon_site', {
        requestId: uuid(),
        uin: invoiceId
      }).then(({
        data
      }) => {
        if (!data.errorMessage) {
          setPhotos(data.fines?.photos);
        }
      }).finally(() => setIsFetchingPhotos(false));
    }
  });
  useEffect(() => {
    if (activePhoto) {
      setIsOpenPhotosModal(true);
    } else {
      setIsOpenPhotosModal(false);
    }
  }, [activePhoto]);
  useEffect(() => {
    if (!isPhotosModalOpen) {
      setActivePhoto(null);
    }
  }, [isPhotosModalOpen]);
  const handleButtonClick = () => {
    setSelectedCharge(charge);
  };
  const handlePhotoClick = (photo: FinePhoto) => {
    setActivePhoto(photo);
  };
  const handleModalPhotoClose = () => {
    setIsOpenPhotosModal(false);
  };
  const handleModalPayChargeClick = () => {
    setIsPayChargeModal(true);
  };
  const handleModalPayChargeClose = () => {
    setIsPayChargeModal(false);
  };
  const handleAcceptPayChargeClick = () => {
    setIsPayChargeLoading(true);
    chargeApi.savePaidChargeUsingPOST('mtsmon_site', {
      requestId: uuid(),
      userId: userProfile.userId,
      charges: [{
        ...charge
      }]
    }).then(({
      data
    }) => {
      if (!data.errorCode) {
        updateChargesResult();
      } else {
        toast('error', 'Не удалось отметить счет как оплаченный', data?.errorMessage || '', {
          withClose: true,
          withTimeout: true,
          timeout: 3000
        });
      }
    }).finally(() => {
      setIsPayChargeLoading(false);
      handleModalPayChargeClose();
    });
  };
  const renderTitle = useCallback(() => <AccordionTitle>
        <Flexwrapper>
          <Text>{title}</Text>
          <Text color={theme.colors.neutral.g300}>{invoiceId}</Text>
        </Flexwrapper>
        <PaymentButtonWrapper>
          <MoneyWrapper>
            <CustomMoney isDiscount={!!amountWithDiscount} value={Number(amount?.base) / 100} />
            {amountWithDiscount && <CustomMoney value={Number(amountWithDiscount) / 100} />}
          </MoneyWrapper>
          {amountWithDiscount && discountSizePercent && discountDate && <Text color="#26CD58" size="md">
              –{discountSizePercent} до {discountDate}
            </Text>}
          <Button onClick={handleButtonClick} size="sm">
            Перейти к оплате
          </Button>
        </PaymentButtonWrapper>
      </AccordionTitle>, [amount, invoiceId, title]);
  const renderText = useCallback(() => {
    const servicePaymentData = paymentParams.filter(param => !param.hidden && paymentDetails[param.name]);
    const paymentParsedData = servicePaymentData.map(param => ({
      name: param.name,
      title: param.title,
      value: paymentDetails[param.name] || null
    }));
    return <>
        <AccordionGridWrapper>
          {paymentParsedData.map((param, idx) => <React.Fragment key={param.name}>
              <Cell rowStart={idx + 1} rowEnd={idx + 1} columnEnd={1} columnStart={1}>
                <Text color={theme.colors.neutral.g300}>{param.title}</Text>
              </Cell>
              <Cell rowStart={idx + 1} rowEnd={idx + 1} columnEnd={2} columnStart={2}>
                <Text>{param.value}</Text>
              </Cell>
            </React.Fragment>)}
        </AccordionGridWrapper>
        {isFetchingPhotos && <SkeletonRect height={100} />}
        {photos?.length > 0 && <PhotosWrapper>
            {photos.map(photo => <Photo key={photo.fileId} onClick={() => handlePhotoClick(photo)} src={photo.previewUrl} />)}
          </PhotosWrapper>}
        <Content.Margin mobTop={24} top={24}>
          <Button onClick={handleModalPayChargeClick} variant="secondary">
            Отметить как оплаченный
          </Button>
        </Content.Margin>
      </>;
  }, [isFetchingPhotos, paymentDetails, paymentParams, photos]);
  return <>
      <Accordion key={invoiceId} items={[{
      title: renderTitle(),
      text: renderText()
    }]} />
      <Modal size="md" open={isPayChargeModalOpen} onClose={handleModalPayChargeClose}>
        <Modal.Body>
          <ModalPayText>Подтвердите, что данный счет оплачен</ModalPayText>
        </Modal.Body>
        <Modal.Footer>
          <ButtonsWrapper>
            <Button disabled={isPayChargeLoading} onClick={handleModalPayChargeClose} variant="secondary">
              Отмена
            </Button>
            <Button isLoading={isPayChargeLoading} onClick={handleAcceptPayChargeClick}>
              Подтвердить
            </Button>
          </ButtonsWrapper>
        </Modal.Footer>
      </Modal>
      <StyledModal closeIcon={<Icon fill={theme.colors.white} icon="baseX24/close" />} onClose={handleModalPhotoClose} open={isPhotosModalOpen}>
        <Modal.Body>
          {!isTablet && <PreviewWrapper>
              {photos.map(photo => <Photo active={photo?.fileId === activePhoto?.fileId} key={photo.fileId} onClick={() => handlePhotoClick(photo)} src={photo.previewUrl} />)}
            </PreviewWrapper>}
          <FullPhotoWrpper>
            <div>
              <FullPhoto src={activePhoto?.originalUrl} />
            </div>
          </FullPhotoWrpper>
        </Modal.Body>
      </StyledModal>
    </>;
};