// tslint:disable
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url'
import { Configuration } from './configuration'
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base'

/**
 *
 * @export
 * @interface AccBalances
 */
export interface AccBalances {
  /**
   *
   * @type {string}
   * @memberof AccBalances
   */
  status?: string
  /**
   *
   * @type {Total}
   * @memberof AccBalances
   */
  total?: Total
}
/**
 *
 * @export
 * @interface BalanceResponse
 */
export interface BalanceResponse {
  /**
   *
   * @type {EmbeddedBalance}
   * @memberof BalanceResponse
   */
  _embedded: EmbeddedBalance
  /**
   *
   * @type {Links}
   * @memberof BalanceResponse
   */
  _links: Links
}
/**
 *
 * @export
 * @interface Balances
 */
export interface Balances {
  /**
   *
   * @type {string}
   * @memberof Balances
   */
  scope: string
  /**
   *
   * @type {Customer}
   * @memberof Balances
   */
  customer: Customer
  /**
   *
   * @type {Array<AccBalances>}
   * @memberof Balances
   */
  accBalances: Array<AccBalances>
}
/**
 *
 * @export
 * @interface Customer
 */
export interface Customer {
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  phone?: string
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  account?: string
}
/**
 *
 * @export
 * @interface EmbeddedBalance
 */
export interface EmbeddedBalance {
  /**
   *
   * @type {Array<Balances>}
   * @memberof EmbeddedBalance
   */
  balances: Array<Balances>
}
/**
 *
 * @export
 * @interface EmbeddedPolicies
 */
export interface EmbeddedPolicies {
  /**
   *
   * @type {Array<Policies>}
   * @memberof EmbeddedPolicies
   */
  policies: Array<Policies>
}
/**
 *
 * @export
 * @interface EwPayPolicies
 */
export interface EwPayPolicies {
  /**
   *
   * @type {string}
   * @memberof EwPayPolicies
   */
  status?: string
}
/**
 *
 * @export
 * @interface Links
 */
export interface Links {
  /**
   *
   * @type {Self}
   * @memberof Links
   */
  self?: Self
}
/**
 *
 * @export
 * @interface Policies
 */
export interface Policies {
  /**
   *
   * @type {string}
   * @memberof Policies
   */
  scope: string
  /**
   *
   * @type {Customer}
   * @memberof Policies
   */
  customer: Customer
  /**
   *
   * @type {Array<EwPayPolicies>}
   * @memberof Policies
   */
  ewPayPolicies: Array<EwPayPolicies>
}
/**
 *
 * @export
 * @interface PoliciesResponse
 */
export interface PoliciesResponse {
  /**
   *
   * @type {EmbeddedPolicies}
   * @memberof PoliciesResponse
   */
  _embedded: EmbeddedPolicies
  /**
   *
   * @type {Links}
   * @memberof PoliciesResponse
   */
  _links: Links
}
/**
 *
 * @export
 * @interface Self
 */
export interface Self {
  /**
   *
   * @type {string}
   * @memberof Self
   */
  href: string
}
/**
 *
 * @export
 * @interface Total
 */
export interface Total {
  /**
   *
   * @type {number}
   * @memberof Total
   */
  amount?: number
  /**
   *
   * @type {string}
   * @memberof Total
   */
  account?: string
}

/**
 * CustApi - axios parameter creator
 * @export
 */
export const CustApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {string} requestId
     * @param {string} customerRef
     * @param {string} scope
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBalance(
      zone: 'b' | 'anonymous',
      requestId: string,
      customerRef: string,
      scope: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'zone' is not null or undefined
      if (zone === null || zone === undefined) {
        throw new RequiredError('zone', 'Required parameter zone was null or undefined when calling getBalance.')
      }
      // verify required parameter 'requestId' is not null or undefined
      if (requestId === null || requestId === undefined) {
        throw new RequiredError(
          'requestId',
          'Required parameter requestId was null or undefined when calling getBalance.'
        )
      }
      // verify required parameter 'customerRef' is not null or undefined
      if (customerRef === null || customerRef === undefined) {
        throw new RequiredError(
          'customerRef',
          'Required parameter customerRef was null or undefined when calling getBalance.'
        )
      }
      // verify required parameter 'scope' is not null or undefined
      if (scope === null || scope === undefined) {
        throw new RequiredError('scope', 'Required parameter scope was null or undefined when calling getBalance.')
      }
      const localVarPath = `/{zone}/domestic/services/customer/balances/realms/me `.replace(
        `{${'zone'}}`,
        encodeURIComponent(String(zone))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (requestId !== undefined) {
        localVarQueryParameter['requestId'] = requestId
      }

      if (customerRef !== undefined) {
        localVarQueryParameter['customerRef'] = customerRef
      }

      if (scope !== undefined) {
        localVarQueryParameter['scope'] = scope
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {string} requestId
     * @param {string} customerRef
     * @param {string} scope
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPolicies(
      zone: 'b' | 'anonymous',
      requestId: string,
      customerRef: string,
      scope: string,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'zone' is not null or undefined
      if (zone === null || zone === undefined) {
        throw new RequiredError('zone', 'Required parameter zone was null or undefined when calling getPolicies.')
      }
      // verify required parameter 'requestId' is not null or undefined
      if (requestId === null || requestId === undefined) {
        throw new RequiredError(
          'requestId',
          'Required parameter requestId was null or undefined when calling getPolicies.'
        )
      }
      // verify required parameter 'customerRef' is not null or undefined
      if (customerRef === null || customerRef === undefined) {
        throw new RequiredError(
          'customerRef',
          'Required parameter customerRef was null or undefined when calling getPolicies.'
        )
      }
      // verify required parameter 'scope' is not null or undefined
      if (scope === null || scope === undefined) {
        throw new RequiredError('scope', 'Required parameter scope was null or undefined when calling getPolicies.')
      }
      const localVarPath = `/{zone}/domestic/services/customer/policies/pay/services/ew/realms/me `.replace(
        `{${'zone'}}`,
        encodeURIComponent(String(zone))
      )
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (requestId !== undefined) {
        localVarQueryParameter['requestId'] = requestId
      }

      if (customerRef !== undefined) {
        localVarQueryParameter['customerRef'] = customerRef
      }

      if (scope !== undefined) {
        localVarQueryParameter['scope'] = scope
      }

      localVarUrlObj.query = { ...localVarUrlObj.query, ...localVarQueryParameter, ...options.query }
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * CustApi - functional programming interface
 * @export
 */
export const CustApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {string} requestId
     * @param {string} customerRef
     * @param {string} scope
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBalance(
      zone: 'b' | 'anonymous',
      requestId: string,
      customerRef: string,
      scope: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BalanceResponse> {
      const localVarAxiosArgs = CustApiAxiosParamCreator(configuration).getBalance(
        zone,
        requestId,
        customerRef,
        scope,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {string} requestId
     * @param {string} customerRef
     * @param {string} scope
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPolicies(
      zone: 'b' | 'anonymous',
      requestId: string,
      customerRef: string,
      scope: string,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PoliciesResponse> {
      const localVarAxiosArgs = CustApiAxiosParamCreator(configuration).getPolicies(
        zone,
        requestId,
        customerRef,
        scope,
        options
      )
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url }
        return axios.request(axiosRequestArgs)
      }
    },
  }
}

/**
 * CustApi - factory interface
 * @export
 */
export const CustApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {string} requestId
     * @param {string} customerRef
     * @param {string} scope
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBalance(
      zone: 'b' | 'anonymous',
      requestId: string,
      customerRef: string,
      scope: string,
      options?: any
    ): AxiosPromise<BalanceResponse> {
      return CustApiFp(configuration).getBalance(zone, requestId, customerRef, scope, options)(axios, basePath)
    },
    /**
     *
     * @param {'b' | 'anonymous'} zone
     * @param {string} requestId
     * @param {string} customerRef
     * @param {string} scope
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPolicies(
      zone: 'b' | 'anonymous',
      requestId: string,
      customerRef: string,
      scope: string,
      options?: any
    ): AxiosPromise<PoliciesResponse> {
      return CustApiFp(configuration).getPolicies(zone, requestId, customerRef, scope, options)(axios, basePath)
    },
  }
}

/**
 * CustApi - object-oriented interface
 * @export
 * @class CustApi
 * @extends {BaseAPI}
 */
export class CustApi extends BaseAPI {
  /**
   *
   * @param {'b' | 'anonymous'} zone
   * @param {string} requestId
   * @param {string} customerRef
   * @param {string} scope
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustApi
   */
  public getBalance(zone: 'b' | 'anonymous', requestId: string, customerRef: string, scope: string, options?: any) {
    return CustApiFp(this.configuration).getBalance(
      zone,
      requestId,
      customerRef,
      scope,
      options
    )(this.axios, this.basePath)
  }

  /**
   *
   * @param {'b' | 'anonymous'} zone
   * @param {string} requestId
   * @param {string} customerRef
   * @param {string} scope
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustApi
   */
  public getPolicies(zone: 'b' | 'anonymous', requestId: string, customerRef: string, scope: string, options?: any) {
    return CustApiFp(this.configuration).getPolicies(
      zone,
      requestId,
      customerRef,
      scope,
      options
    )(this.axios, this.basePath)
  }
}
