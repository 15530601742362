import React, { FC } from 'react'
import { Icon } from '@mtsbank/ui-kit'
import { Binding } from '@open-api/ump/ewallet-profile'
import { BindingType, CardType } from '@root/types/bindings'
import { cardIconMapping } from '../../constants/cardIconMapping'

export const BindingIcon: FC<{ binding: Binding }> = ({ binding }) => {
  let iconSrc = cardIconMapping[CardType.UNKNOWN]

  if (binding?.cardType) {
    iconSrc = cardIconMapping[binding.cardType] || cardIconMapping[CardType.UNKNOWN]
  }

  if (binding?.bindingType === BindingType.MTS_ACCOUNT) {
    iconSrc = 'baseX24/ic-mobile'
  }

  if (binding?.bindingType === BindingType.EMONEY_ACCOUNT) {
    iconSrc = 'baseX24/ic-wallet'
  }

  return <Icon icon={iconSrc} size="lg" />
}
