import React, { FC, useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { debounce } from 'lodash';
import { MoneyInput } from '@mtsbank/ui-kit';
import { FieldProps, WithoutAuthFormValues } from '@components/TspPayee/withoutAuthorization/type';
import { uuid } from '@root/utils/generateUuid';
import { getPcekZone } from '@root/components/CardToCard/utils/helpers';
import { FormatAliasPhone, formatPhoneNumber } from '@root/utils/formatPhoneNumber';
import { Payee } from '@open-api/ump/catalog-manager';
import { authHelper } from '@root/utils/authHelper/AuthHelper';
import { custApi } from '@root/api/ump/cust';
import { uprsRecommendationApi } from '@root/api/ump/uprs-recommendation';
import { AmountRecommendationResponse } from '@open-api/ump/uprs-recommendation';
import { MOBILE_PARAM_NAME } from '../../constants';
import { PhoneField } from '../../formFields/PhoneField';
import { FieldWrapper, FieldsWrapper } from './styled';
interface Props {
  setPayeeData?: (payee: Payee) => void;
  setRecommendedAmount: (recomendation: AmountRecommendationResponse) => void;
  field: FieldProps;
}
const scope = 'ALL';
export const MtsPhoneFields: FC<Props> = ({
  setRecommendedAmount,
  field
}) => {
  const {
    values,
    errors
  } = useFormikContext<WithoutAuthFormValues>();
  const zone = getPcekZone();
  let optionsAnonymous;
  if (zone === 'anonymous') {
    optionsAnonymous = {
      headers: {
        'client-Id': 'mts-money-web-mtsid'
      }
    };
  }
  const phoneError = errors[MOBILE_PARAM_NAME];
  const [currentBalance, setCurrentBalance] = useState(null);
  const {
    isAuthenticated
  } = authHelper;
  const isAuth = isAuthenticated();
  const isBalanceRequired = isAuth;
  useEffect(() => {
    if (!phoneError) {
      debounceGetPaymentService((values[field.name] as string));
    }
  }, [values[field.name], errors, phoneError]);
  const debounceGetPaymentService = debounce((phone: string) => {
    const formattedNumber = formatPhoneNumber(phone, FormatAliasPhone.DIGIT11);
    if (formattedNumber && isAuth) {
      uprsRecommendationApi.getRecommendedPaymentAmount(formattedNumber, optionsAnonymous).then(({
        data
      }) => {
        if (data.amount) {
          setRecommendedAmount(data);
        } else {
          setRecommendedAmount(null);
        }
      });
      custApi.getBalance(zone, uuid(), formattedNumber, scope, optionsAnonymous)
      // eslint-disable-next-line no-underscore-dangle
      .then(({
        data
      }) => setCurrentBalance(data?._embedded?.balances[0]?.accBalances[0]?.total?.amount || null)).catch(() => setCurrentBalance(null));
    }
  }, 700, {
    leading: false,
    trailing: true
  });
  useEffect(() => {
    if (phoneError) {
      setCurrentBalance(null);
    }
  }, [phoneError]);
  return <FieldsWrapper>
      <FieldWrapper>
        <Field {...field} component={PhoneField} size="lg" />
      </FieldWrapper>
      {isBalanceRequired && <FieldWrapper>
          <Field allowDecimal readOnly value={currentBalance} component={MoneyInput} label="Текущий баланс" />
        </FieldWrapper>}
    </FieldsWrapper>;
};