import { formatDate } from '@utils/formatDate/formatDate'
import FormUtils from '@components/PaymentMethodSelector/paymentDetector/CardPayment/FormUtils'
import { TransferResponse } from '@open-api/ump/ewallet'
import { ewalletApi } from '@root/api/ump/ewallet'
import { getPcekZone } from '@root/components/CardToCard/utils/helpers'
import { SberPayContextProps } from '@root/pages/perevod_v_sber'
import { ConfirmationType } from '../types'

const BROWSER_ACCEPT_HEADER = '*/*'
const DEFAULT_COLOR_DEPTH = 1
const colorDepthPossibleValues = [1, 4, 8, 15, 16, 24, 32, 48]
const getColorDepth = (value: number, defaultValue: number = DEFAULT_COLOR_DEPTH) =>
  colorDepthPossibleValues.reduce((result, item) => (item <= value ? item : result), defaultValue)

interface ThreeDs2PcekAcsRq {
  url: string
  creq: string
}

interface ThreeDs2PcekAuthProps {
  mdOrder: string
  callbackUrl: string
  url: string
  threeDsMethodUrl?: string
  threeDsMethodData?: string
}

class ThreeDs2PcekAuth {
  public static call(
    acsRequest: ThreeDs2PcekAuthProps,
    sberPayContext?: SberPayContextProps
  ): Promise<TransferResponse> {
    const zone = getPcekZone(sberPayContext?.isAuthMTS)
    const { colorDepth, width, height } = window.screen
    const { userAgent, language } = navigator

    return new Promise<TransferResponse>((resolve, reject) => {
      if (acsRequest?.threeDsMethodUrl) {
        const iframeName = 'threeDsForm'
        const iframe = document.createElement('iframe')

        iframe.setAttribute('name', iframeName)
        iframe.setAttribute('style', 'width:0; height:0; opacity:0, visibility:hidden')
        iframe.setAttribute('sandbox', 'allow-forms allow-scripts allow-same-origin')

        document.body.append(iframe)

        const formElement = FormUtils.createForm(acsRequest.threeDsMethodUrl)

        formElement.setAttribute('target', iframeName)
        formElement.appendChild(FormUtils.createField('threeDSMethodData', acsRequest.threeDsMethodData))

        document.body.append(formElement)
        formElement.submit()
      }

      let options = sberPayContext?.headers

      if (zone === 'anonymous') {
        options = {
          headers: {
            'client-Id': 'mts-money-web-mtsid',
            FhpSessionId: window.gibSessionId,
            FhpRequestId: window.gibRequestId,
          },
        }
      }

      // eslint-disable-next-line no-underscore-dangle
      ewalletApi
        ._3ds2proceed(
          zone,
          acsRequest.mdOrder,
          {
            threeDsRequestorUrl: acsRequest.threeDsMethodUrl,
            browserJavaEnabled: typeof navigator.javaEnabled === 'function' && navigator.javaEnabled(),
            browserLanguage: language,
            browserColorDepth: getColorDepth(colorDepth),
            browserScreenHeight: height,
            browserScreenWidth: width,
            browserTZ: parseInt(formatDate(new Date(), '%Z'), 10),
            browserUserAgent: userAgent,
            notificationUrl: acsRequest.callbackUrl,
            browserAcceptHeader: BROWSER_ACCEPT_HEADER,
          },
          options
        )
        .then((response) => {
          if (
            // challenge flow
            response?.data?.acsUrl &&
            response?.data?.cReq &&
            response?.data?.confirmationType === ConfirmationType.FINISH_3DS2
          ) {
            this.acsCall({
              url: response.data.acsUrl,
              creq: response.data.cReq,
            })
          } else {
            resolve(response.data)
          }
        })
        .catch((error) => reject(error))
    })
  }

  static acsCall(acsRequest: ThreeDs2PcekAcsRq) {
    const formElement = FormUtils.createForm(acsRequest.url)

    formElement.appendChild(FormUtils.createField('creq', acsRequest.creq))

    document.body.append(formElement)
    formElement.submit()
  }
}

export { ThreeDs2PcekAuth }
